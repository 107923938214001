
import React, { useState } from 'react';
import { Box,Typography, Hidden } from '@mui/material';
import CustomDateSelector from '../../components/customDateSelector';
import OperationDashboard from './operationDashboard';

const OperationLayout = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date.toDate());
        console.log(date.toDate());
    };

    return (
        <Box p={2}>
            <Hidden smDown>
                <Typography variant="h6" color="primary">Tabla Resumen Diario</Typography>
            </Hidden>
            <CustomDateSelector
                currentDate={selectedDate}
                onDateSelected={handleDateChange}
            />
            <OperationDashboard dateInfo={selectedDate} />
        </Box>
    );
};

export default OperationLayout;
