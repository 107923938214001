import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../components/authContext';
import { Box, TextField , Typography, Checkbox, Button, FormControlLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import Alert from '@mui/lab/Alert';


const NewVarietyForm = () => {
    const [variedadNombre, setVariedadNombre] = useState('');
    const { authToken } = useContext(AuthContext);
    const [VarityError, setVarityError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [confirmAdd, setConfirmAdd] = useState(false);
    const navigate = useNavigate();


    const handleSubmit = (e) => {
        e.preventDefault();

        console.log("variedadNombre: " + variedadNombre);
        
        if (!variedadNombre) {
            setVarityError(true);
            setErrorMessage("Se requiere agregar un nombre a la variedad");
            return;
        }
        else {
            setVarityError(false);
            setErrorMessage(null);
        }

        // Create the JSON payload
        const payload = {
            variedad_nombre: variedadNombre
        };

        console.log(payload);

        // Make the HTTP request
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/variety/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(payload)
        })
        .then(response => {
            if (response.ok) {
                // Redirect to varietyDashboard on success
                setConfirmAdd(true);
            } else {
                switch (response.status) {
                    case 403:
                        setErrorMessage('No autorizado');
                        break;
                    case 400:
                      setErrorMessage('Falta el campo requerido: variedad_nombre');
                      setVarityError(true);
                      break;
                    case 422:
                        setErrorMessage('Falta el campo requerido: variedad_nombre');
                        setVarityError(true);
                        break;
                    case 500:
                      setErrorMessage('Error del servidor');
                      break;
                    default:
                      setErrorMessage('Error: ' + response.status.toString());
                  }
                console.error('Error:', response.status);
            }
        })
        .catch(error => {
            // Handle network error
            console.error('Error:', error);
        });
    };

    const MyStyledTypography = styled(Typography)(({ theme }) => ({
        fontWeight: 'bold',
        fontSize: 20,
        marginBottom: theme.spacing(2),
    }));

    //syled button
    const MyStyledButton = styled(Button)(({ theme }) => ({
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }));

    return (
        <div>
            {!confirmAdd ? (
                <form onSubmit={handleSubmit}>
                    <Box display="flex" flexDirection="column"  maxWidth="400px" margin="0 auto">
                        <MyStyledTypography variant="h6" component="div">
                                Crear Nueva Variedad
                        </MyStyledTypography>
                        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                        <TextField
                            label="Nombre de Variedad"
                            value={variedadNombre}
                            onChange={(e) => {
                                setVariedadNombre(e.target.value);
                            }}
                            required
                        />
                        <MyStyledButton type="submit" variant="contained" color="primary">
                            Agregar Variedad
                        </MyStyledButton>
                    </Box>
                </form>
            ) : (
                <Box display="flex" flexDirection="column"  maxWidth="400px" margin="0 auto"> 
                    <MyStyledTypography variant="h6" align='center'>
                        El item "{variedadNombre}" ha sido agregado a la tabla de variedades.
                    </MyStyledTypography>
                    <MyStyledButton
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate("/admin/varietyDashboard")}
                    >
                        Volver a la tabla
                    </MyStyledButton>
                </Box>
            )}            
                
        </div>
    );
};

export default NewVarietyForm;
