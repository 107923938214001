import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../components/authContext';
import { Container, InputAdornment, Box, TextField , Typography, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
// ...otros imports...

const VarietyDashboard = () => {
    const [varieties, setVarieties] = useState([]);
    const [update, setUpdate] = useState(false);
    const { authToken } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/variety/getAll`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => setVarieties(data))
            .catch(error => console.log(error));
    }, [update]);

    const handleDelete = async (itemId, itemName, itemType) => {
        navigate(`/forms/deleteItem/${itemId}/${itemName}/${itemType}`);
    };

    const handleEdit = async (variedad_id) => {
        // const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/worker/edit`, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': `Bearer ${authToken}`
        //     },
        //     body: JSON.stringify({
        //         id: worker_id
        //     })

        // });

        // if (response.ok) {
        //     setWorkers(workers.filter(worker => worker.worker_id !== worker_id));
        // } else {
        //     console.log(worker_id);
        //     console.log(response.status.toString());
            
        // }
    };

    const handleActiveToggle = async (variedad_id, variedad_activa) => {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/variety/edit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({
                variedad_id: variedad_id,
                variedad_activa: !variedad_activa
            })

        });

        if (response.ok) {
            setUpdate(!update);
        } else {
            console.log(response.status.toString());
            
        }
    };

    const handleAddVariety = () => {
        // Aquí puedes manejar la lógica para agregar una variedad
    };

    return (
        <Container>
            <Typography variant="h6" component="div">
                Administración de Variedades
            </Typography>
            <Toolbar>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Box>
                        <TextField
                            label="Buscar Variedad"
                            variant="outlined"
                            size="small"
                            style={{ marginRight: '1rem' }}
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title="Buscar">
                                        <IconButton color="primary" onClick={() => console.log('Buscar')}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                            }}
                        />
                        <Tooltip title="Agregar Variedad">
                            <Button color="primary" variant='contained' startIcon={<AddIcon />} onClick={() => navigate("/forms/newVariety")}>
                                Agregar
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>
            </Toolbar>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nombre de Variedad</TableCell>
                            <TableCell>Variedad Activa</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {varieties.map((variety) => (
                            <TableRow key={variety.variedad_id}>
                                <TableCell>{variety.variedad_nombre}</TableCell>
                                <TableCell>
                                    {variety.variedad_activa ? (
                                        <Tooltip title={'Desactivar'}>
                                            <Button startIcon={<CheckCircleOutlined color="primary"/>} onClick={() => handleActiveToggle(variety.variedad_id, variety.variedad_activa)}>
                                                Activa
                                            </Button>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title={'Activar'}>
                                            <Button color="error" startIcon={<CancelOutlined color="error"/>} onClick={() => handleActiveToggle(variety.variedad_id, variety.variedad_activa)}>
                                                Inactiva
                                            </Button>
                                        </Tooltip>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Editar Variedad">
                                        <IconButton color="primary" onClick={() => handleEdit(variety.variedad_id)}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Eliminar Variedad">
                                        <IconButton color="error" onClick={() => handleDelete(variety.variedad_id, variety.variedad_nombre,"variety")}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default VarietyDashboard;