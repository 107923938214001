import React, {useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../components/authContext';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar, GridToolbarContainer, esES} from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';


const VISIBLE_FIELDS = ['name', 'paternal', 'maternal', 'rut', 'boxes'];

function createData(id, name, paternal ,maternal, rut, domicilio, comuna, genero, boxes) {

    //si el rut parte con mas de 30 millones antes del guion entonces la variable pais es Bolibia si no es chile
    let pais = 'CHILE';
    if(domicilio===''){
        pais = '';
    }

    let rutNoDash = rut.split('-')[0] + rut.split('-')[1];

    return {
        'id': id,
        'CUENTA_ORIGEN' : '',
        'MONEDA_ORIGEN' : 'CLP',
        'CUENTA_DESTINO' : rutNoDash,
        'MONEDA_DESTINO' : 'CLP',
        'CODIGO_BANCO' : '12',
        'RUT' : rutNoDash,
        'NOMBRES': name + ' ' + paternal + ' ' + maternal,
        'MONTO': 0,
        'GLOSA': '',
        'EMAIL': '',
        'MSG': '',
        'GLOSA_CARTOLA_ORIGEN': '',
        'GLOSA_CARTOLA_BENEFICIARIO': '',
        'PAIS_DOCUMENTO': pais,
        'DOMICILIO': domicilio,
        'COMUNA': comuna,
        'CAJAS': boxes,
    };
  }

const columns_data = [
    {field: 'id', headerName: 'id', hide: true},
    {field: 'CUENTA_ORIGEN', headerName: "Cuenta origen (obligatorio)" , width: 200},
    {field: 'MONEDA_ORIGEN', headerName: "Moneda origen (obligatorio)" , width: 120},
    {field: 'CUENTA_DESTINO', headerName: "Cuenta destino (obligatorio)" , width: 200},
    {field: 'MONEDA_DESTINO', headerName: "Moneda destino (obligatorio)" , width: 120},
    {field: 'CODIGO_BANCO', headerName: "Código banco destino (obligatorio solo si banco destino no es Santander)" , width: 100},
    {field: 'RUT', headerName: "RUT beneficiario (obligatorio solo si banco destino no es Santander)" , width: 150},
    {field: 'NOMBRES', headerName: "Nombre beneficiario (obligatorio solo si banco destino no es Santander)", width: 200},
    {field: 'MONTO', headerName: "Monto transferencia (obligatorio)", width: 200},
    {field: 'GLOSA', headerName: "Glosa personalizada transferencia (opcional)", width: 200},
    {field: 'EMAIL', headerName: "Correo beneficiario (opcional)", width: 200},
    {field: 'MSG', headerName: "Mensaje correo beneficiario (opcional)", width: 200},
    {field: 'GLOSA_CARTOLA_ORIGEN', headerName: "Glosa cartola originador (opcional)", width: 200},
    {field: 'GLOSA_CARTOLA_BENEFICIARIO', headerName: "Glosa cartola beneficiario (opcional, solo aplica si cuenta destino es Santander)", width: 200},
    {field: 'PAIS_DOCUMENTO', headerName: 'PAIS_DOCUMENTO', width: 150},
    {field: 'DOMICILIO', headerName: 'DOMICILIO', width: 100},
    {field: 'COMUNA', headerName: 'COMUNA', width: 100},
    {field: 'CAJAS', headerName: 'CAJAS', width: 100},
  ]

  const initialState_data = {
    columns: {
        columnVisibilityModel :{
            checkbox: false,
            id: false,
            CUENTA_ORIGEN: true,
            MONEDA_ORIGEN: true,
            CUENTA_DESTINO: true,
            MONEDA_DESTINO: true,
            CODIGO_BANCO: true,
            RUT: true,
            NOMBRES: true,
            MONTO: true,
            GLOSA: true,
            EMAIL: true,
            MSG: true,
            GLOSA_CARTOLA_ORIGEN: true,
            GLOSA_CARTOLA_BENEFICIARIO: true,
            PAIS_DOCUMENTO: true,
            DOMICILIO: true,
            COMUNA: true,
            CAJAS: true,
        }
    },
    pagination: { paginationModel: { pageSize: 10 } },
  }

function CustomToolbar({startDate, endDate}){
  return (
    <GridToolbarContainer>
        <GridToolbar csvOptions={{
        fileName: 'Pagos_Temporeros_' + startDate + '_' + endDate,
        utf8WithBom: true,
        }}
    />
    </GridToolbarContainer>
  );
}

export default function WorkerPayTable({dateStart, dateEnd}) {
  const navigate = useNavigate();
  const { authToken } = useContext(AuthContext);
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState(columns_data);
  const [initialState, setInitialState] = useState(initialState_data);
  const isXsScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  let width_value = 200;
  let colum_visivility= true;

  useEffect(() => {
    setIsLoading(true);

    if(!dateStart){
      return;
    }

    if(!dateEnd){
      return;
    }

    console.log("Start Date: ");
    console.log(dateStart);
    const startDateWithTimeSetToNoon = new Date(dateStart);
    startDateWithTimeSetToNoon.setHours(12, 0, 0, 0);
    const startDateInfoString = startDateWithTimeSetToNoon.toISOString().split('T')[0];
    setStartDate(startDateInfoString);
    console.log(startDateInfoString);

    console.log("End Date: ");
    console.log(dateEnd);
    const endDateWithTimeSetToNoon = new Date(dateEnd);
    endDateWithTimeSetToNoon.setHours(12, 0, 0, 0);
    const endDateInfoString = endDateWithTimeSetToNoon.toISOString().split('T')[0];
    setEndDate(endDateInfoString);
    console.log(endDateInfoString);
    
    const fetchWorkers = async () => {
      setIsLoading(true);
      const payload = {
        startDate: startDateInfoString,
        endDate: endDateInfoString
      };
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/boxes/getBoxCountByDateInterval`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`
          },
          body: JSON.stringify(payload),
      });

      if (response.ok) {
          const boxes = await response.json();
          let boxlist = [];
          boxes.map((box) => { 
            boxlist.push(
              createData(
                box.id, box.Nombre, 
                box.Apellido_Paterno, 
                box.Apellido_Materno, 
                box.Rut,
                box.Domicilio, 
                box.Comuna, box.Genero,
                box.Cajas
              )
            )
          })
          setRows(boxlist);
          setIsLoading(false);
      } else {
          // Manejar el error
          setIsLoading(false);
      }
    };

    fetchWorkers();
  }, [authToken, dateStart, dateEnd]);


  if (isLoading) {
    return <p>Cargando...</p>;
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
            //checkboxSelection
            disableRowSelectionOnClick
            density="compact"
            columns = {columns_data}
            rows = {rows}
            initialState = {initialState_data}
            slots={{ toolbar: () => <CustomToolbar startDate={startDate} endDate={endDate}/> }} 
            sx={{ '--DataGrid-overlayHeight': '300px' }}
            pageSizeOptions={[5, 10, 25, 50]}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}

        />
    </div>
  );
}