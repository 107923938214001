import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Create from "./pages/create";
import Login from "./pages/login";
import Notes from "./pages/notes";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { indigo, teal } from '@mui/material/colors';
import Layout from './components/layout';
import { AuthProvider } from './components/authContext';
import PrivateRoute from './components/PrivateRoute';

//Forms pages
import NewVarietyForm from './pages/Admin/Forms/newVariety';
import NewCompanyForm from './pages/Admin/Forms/newCompany';
import NewTractorForm from './pages/Admin/Forms/newTractor';
import NewWorkerForm from './pages/Admin/Forms/newWorker';
import DeleteItemForm from './pages/Admin/Forms/deleteItem';

//Admin pages
import UserDashboard from './pages/Admin/userDashboard';
import VarietyDashboard from './pages/Admin/varietyDashboard';
import CompanyDashboard from './pages/Admin/companyDashboard';
import TractorDashboard from './pages/Admin/tractorDashboard';
import WorkerDashboard from './pages/Admin/workerDashboard';

//Operation pages
import EnhancedTable from './pages/Operation/boxes';
import OperationLayout from './pages/Operation/operationLayout';
import AddBoxLayout from './pages/Operation/addBox/addBoxLayout';
import OperationSummary from './pages/Operation/operationSummary';
import OperationWorkerView from './pages/Operation/operationWorkerView';

//Dispatcher pages
import DispatcherLayout from './pages/Dispatcher/dispatcherLayout';

//Report pages
import ReportLayout from './pages/Reports/reportsLayout';
import WorkerDetailSummaryLayout from './pages/Reports/workerDetailSummaryLayout';

const theme = createTheme({
  palette: {
    primary: {
      light: teal[500],
      main: teal[700],
      dark: teal[900],
      contrastText: '#fff',
    },
    secondary: {
      light: indigo[500],
      main: indigo[700],
      dark: indigo[900],
      contrastText: '#fff',
    },
  },
  transitions: {
    duration: {
      enteringScreen:2000, // Duración en milisegundos
      leavingScreen: 2000, // Duración en milisegundos
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
       <script src="http://localhost:8097"></script>
      <Router>
        <AuthProvider>
          <Layout>
            <Routes>
              <Route path="/" element={<Notes />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forms" element={ <PrivateRoute roles={['Admin']}/> } >
                <Route path="/forms/newVariety" element={<NewVarietyForm />} />
                <Route path="/forms/newCompany" element={<NewCompanyForm />} />
                <Route path="/forms/newTractor" element={<NewTractorForm />} />
                <Route path="/forms/newWorker" element={<NewWorkerForm />} />
                <Route path="/forms/deleteitem/:itemId/:itemName/:itemType" element={<DeleteItemForm />} />
              </Route>
        
              <Route path="/admin" element={ <PrivateRoute roles={['Admin']}/> } >
                <Route path="/admin" element={<div>Admin</div>} />
                <Route path="/admin/userDashboard" element={<UserDashboard />} />
                <Route path="/admin/varietyDashboard" element={<VarietyDashboard />} />
                <Route path="/admin/companyDashboard" element={<CompanyDashboard />} />
                <Route path="/admin/tractorDashboard" element={<TractorDashboard />} />
                <Route path="/admin/workerDashboard" element={<WorkerDashboard />} />
              </Route>

              <Route path="/report" element={ <PrivateRoute roles={['Admin', 'Supervisor']}/> } >
                <Route path="/report" element={<ReportLayout/>} />
                <Route path="/report/dashboard" element={<ReportLayout/>} />
                <Route path="/report/workerdetail" element={<WorkerDetailSummaryLayout/>} />
              </Route>

              <Route path="/operation" element={ <PrivateRoute roles={['Admin', 'Supervisor', 'BookKeeper']}/> } >
                <Route path="/operation" element={<div>Operaciones</div>} />
                <Route path="/operation/dashboard" element={<OperationLayout/>} />
                <Route path="/operation/workerView" element={<OperationWorkerView />} />
                <Route path="/operation/addBox" element={<AddBoxLayout />} />
                <Route path="/operation/summary" element={<OperationSummary />} />

              </Route>

              <Route path="/dispatcher" element={ <PrivateRoute roles={['Admin', 'Supervisor', 'Despachador']}/> } >
                <Route path="/dispatcher" element={<DispatcherLayout/>} />
                <Route path="/dispatcher/dashboard" element={<DispatcherLayout/>} />
                <Route path="/dispatcher/orchard" element={<div>Despachador orchard</div>} />
                <Route path="/dispatcher/truck" element={<div>Despachador truck</div>} />
                <Route path="/dispatcher/inventory" element={<div>Despachador inventory</div>} />
              </Route>

              <Route path="/worker" element={ <PrivateRoute roles={['Admin', 'Supervisor', 'BookKeeper']}/> } >
                <Route path="/worker" element={<div>Trabajador</div>} />
                <Route path="/worker/dashboard" element={<WorkerDashboard />} />
                <Route path="/worker/add" element={<NewWorkerForm />} />
              </Route>

            </Routes>
          </Layout>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;