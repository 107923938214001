import React, {useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../components/authContext';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, TextField, Typography, Button, Hidden, InputAdornment, IconButton} from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarExport, GridToolbarContainer, GridFilterOperato, esES} from '@mui/x-data-grid';
import AddBoxIcon from '@mui/icons-material/AddBox';
import useMediaQuery from '@mui/material/useMediaQuery';


const VISIBLE_FIELDS = ['name', 'paternal', 'maternal', 'rut', 'boxes'];

function createData(id, name, paternal ,maternal, rut, boxes) {
    return {
      id,
      name,
      paternal ,
      maternal,
      rut,
      boxes,
    };
  }

const columns_data = [
    {field: 'id', headerName: 'ID', hide: true},
    {field: 'name', headerName: 'Nombre', width: 150},
    {field: 'paternal', headerName: 'Apellido Paterno', width: 150},
    {field: 'maternal', headerName: 'Apellido Materno', width: 150},
    {field: 'rut', headerName: 'Rut', width: 150},
    {field: 'boxes', headerName: 'Cajas', width: 100},
  ]

  const initialState_data = {
    columns: {
        columnVisibilityModel :{
            checkbox: false,
            id: false,
            paternal: true,
            maternal: true,
            rut: true,
        }
    },
    pagination: { paginationModel: { pageSize: 10 } },
  }

function CustomToolbar({dateInfo}){
  const navigate = useNavigate(); 
  return (
    <GridToolbarContainer>
      <Hidden smDown>
      <GridToolbar csvOptions={{
        fileName: 'Resumen_Diario_' + dateInfo,
        utf8WithBom: true,
        }}
      />
      </Hidden>
      <Button
          variant="text"
          color="primary"
          onClick={() => navigate(`/operation/addbox/`)}
          sx={{ marginTop: theme => theme.spacing(0.5), marginLeft: theme => theme.spacing(0)}}
          startIcon={<AddBoxIcon />}
      >
          Agregar
      </Button>
    </GridToolbarContainer>
  );
}

export default function OperationDashbord(dateInfo) {
  const navigate = useNavigate();
  const { authToken } = useContext(AuthContext);
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState(columns_data);
  const [initialState, setInitialState] = useState(initialState_data);
  const isXsScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [dateString, setDateString] = useState('');

  let width_value = 200;
  let colum_visivility= true;

  useEffect(() => {
    setIsLoading(true);

    if(!dateInfo){
      return;
    }

    if(isXsScreen){

      console.log("is xs screen");
    }
    else{
      console.log("is not xs screen");
    }

    const dateWithTimeSetToNoon = new Date(dateInfo.dateInfo);
    dateWithTimeSetToNoon.setHours(12, 0, 0, 0);
    const dateInfoString = dateWithTimeSetToNoon.toISOString().split('T')[0];
    setDateString(dateInfoString);
    console.log(dateInfoString);
    
    
    const fetchWorkers = async () => {
      setIsLoading(true);
      const payload = {
        dateInfo: dateInfoString
      };
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/boxes/getBoxCountOnDate`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`
          },
          body: JSON.stringify(payload),
      });

      if (response.ok) {
          const boxes = await response.json();
          let boxlist = [];
          boxes.map((box) => { 
            boxlist.push(
              createData(
                box.id, box.Nombre, 
                box.Apellido_Paterno, 
                box.Apellido_Materno, 
                box.Rut, box.Cajas
              )
            )
          })
          setRows(boxlist);
          setIsLoading(false);
      } else {
          // Manejar el error
          setIsLoading(false);
      }
    };

    fetchWorkers();
  }, [authToken, dateInfo]);



  
  //rows with names of fairy tail characters
  const rows_data = [
    {id: 1, name: 'Natsu', paternal: 'Dragneel', maternal: 'N/A', rut: '18.000.000-0', boxes: 100},
    {id: 2, name: 'Lucy', paternal: 'Heartfilia', maternal: 'N/A', rut: '18.000.000-0', boxes: 100},
    {id: 3, name: 'Erza', paternal: 'Scarlet', maternal: 'N/A', rut: '18.000.000-0', boxes: 100},
    {id: 4, name: 'Gray', paternal: 'Fullbuster', maternal: 'N/A', rut: '18.000.000-0', boxes: 100},
    {id: 5, name: 'Wendy', paternal: 'Marvell', maternal: 'N/A', rut: '18.000.000-0', boxes: 100},
    {id: 6, name: 'Gajeel', paternal: 'Redfox', maternal: 'N/A', rut: '18.000.000-0', boxes: 100},
    {id: 7, name: 'Juvia', paternal: 'Lockser', maternal: 'N/A', rut: '18.000.000-0', boxes: 100},
    {id: 8, name: 'Levy', paternal: 'McGarden', maternal: 'N/A', rut: '18.000.000-0', boxes: 100},
    {id: 9, name: 'Mirajane', paternal: 'Strauss', maternal: 'N/A', rut: '18.000.000-0', boxes: 100},
    {id: 10, name: 'Laxus', paternal: 'Dreyar', maternal: 'N/A', rut: '18.000.000-0', boxes: 100},
  ]

  const handleAddBox = (itemId) => {
    navigate(`/operation/addbox/${itemId}/`)
  };

  if (isLoading) {
    return <p>Cargando...</p>;
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
            //checkboxSelection
            disableRowSelectionOnClick
            density="compact"
            columns = {columns_data}
            rows = {rows}
            initialState = {initialState_data}
            slots={{ toolbar: () => <CustomToolbar dateInfo={dateString}/> }} 
            sx={{ '--DataGrid-overlayHeight': '300px' }}
            pageSizeOptions={[5, 10, 25]}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        />
    </div>
  );
}