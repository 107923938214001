import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../components/authContext';
import {Box} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const ReciveTractor_Summary = ({ bins, totes, tractor, onConfirm}) => {
    const { authToken } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleButtonClick = async () => {
        setLoading(true);
        setError(false);

        try {
            // Create the request body
            const requestBody = {
                bins: bins,
                totes: totes,
                idTractor: tractor.tractor_id,
            };

            // Make a POST request to the backend server with the displayed information
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/reciveTractor/add`, {
                method: 'POST',
                body: JSON.stringify(requestBody),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            });

            if (response.status === 200) {
                // If the backend server returns a 200 status code, call the onConfirm function
                onConfirm();
            } else if (response.status === 400) {
                // If the backend server returns a 400 status code, display an error message
                setError(true);
            }
        } catch (error) {
            // Handle any errors that occur during the request
            setError(true);
        }

        setLoading(false);
    };

    return (
        <Card sx={{marginTop:3}}>
            <CardContent>
                {/* Add the title using Typography */}
                <Typography variant="h6" color="primary">Recivir Fruta desde Huerto</Typography>

                {/* Display the information supplied */}
                <p>Bins: {bins}</p>
                <p>Totes: {totes}</p>
                <p>Tractor: {tractor.tractor_color}</p>

                {/* Display a loading spinner while the server is processing the request */}
                {loading && 
                <Box sx={{width:'100%', display:'flex', justifyContent:'center', marginTop:2, marginBottom:2}}>
                    <CircularProgress />
                </Box>
                }

                {/* Display an error message if the backend server returns a 400 status code */}
                {error && <p>Error: Failed to process request</p>}

                {/* Button that calls the handleButtonClick function */}
                <Box sx={{display:'flex', justifyContent:'center'}}>
                    <Button variant='contained' onClick={handleButtonClick}>Confirm</Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default ReciveTractor_Summary;

