import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../components/authContext';
import { Container, InputAdornment, Box, TextField , Typography, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';


const TractorDashboard = () => {
    const [tractors, setTractors] = useState([]);
    const { authToken } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/tractor/getAll`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => setTractors(data))
            .catch(error => console.log(error));
    }, []);

    const handleAdd = () => {
        navigate("/forms/newTractor");
    };

    // const handleDelete = async (tractor_id) => {
    //     const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/tractor/delete`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${authToken}`
    //         },
    //         body: JSON.stringify({
    //             tractor_id: tractor_id
    //         })

    //     });

    //     if (response.ok) {
    //         setTractors(tractors.filter(tractor => tractor.tractor_id !== tractor_id));
    //     } else {
    //         console.log(tractor_id);
    //         console.log(response.status.toString());
            
    //     }
    // };

    const handleDelete = async (itemId, itemName, itemType) => {
        navigate(`/forms/deleteItem/${itemId}/${itemName}/${itemType}`);
    };

    const handleEdit = async (tractor_id) => {
        
    };

    return (
        <Container>
            <Typography variant="h6" component="div">
                Administración de Tractores
            </Typography>
            <Toolbar>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Box>
                        <TextField
                            label="Buscar usuarios"
                            variant="outlined"
                            size="small"
                            style={{ marginRight: '1rem' }}
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton color="primary" onClick={() => console.log('Buscar')}>
                                    <SearchIcon />
                                </IconButton>
                                </InputAdornment>
                            ),
                            }}
                        />
                        <IconButton color="primary" onClick={() => console.log('Agregar filtro')}>
                            <FilterListIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={handleAdd}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Toolbar>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Tractor</TableCell>
                            <TableCell>Patente</TableCell>
                            <TableCell>Capacidad en bins</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tractors.map((tractor) => (
                            <TableRow key={tractor.tractor_id}>
                                <TableCell>
                                    <IconButton color="secondary" onClick={() => handleEdit(tractor.tractor_id)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton color="error" onClick={() => handleDelete(tractor.tractor_id, tractor.tractor_color,"tractor")}>
                                        <DeleteIcon />
                                    </IconButton>
                                    {tractor.tractor_color}
                                </TableCell>
                                <TableCell>{tractor.tractor_patente}</TableCell>
                                <TableCell>{tractor.tractor_capacidad}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default TractorDashboard;