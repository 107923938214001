import React, { useState, useContext } from "react";
import { AppBar, Drawer, Toolbar, Typography, Box, Avatar, IconButton, Button, Hidden} from "@mui/material";
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import { AuthContext } from './authContext';
import { Link } from 'react-router-dom'; // Asegúrate de importar Link de react-router-dom
import { useNavigate } from 'react-router-dom';
import AppSideMenu from './appSideMenu';



const drawerWidth = 240;

const Layout = ({ children }) => {
    const [open, setOpen] = useState(false);
    const { authToken, setAuthToken, userType, setUserType, userName, setUserName } = useContext(AuthContext); // Agrega esto
    const [openAdmin, setOpenAdmin] = useState(false);
    const navigate = useNavigate();

    const handleUserDashboard = () => {
      // Suponiendo que 'user' es un objeto que contiene la información del usuario logueado
      //if (userType === 'admin') {
        navigate('/userDashbord');
      //} else {
        console.log('No tienes permiso para acceder a esta página');
      //}
    };

    const handleLogout = () => {
        setAuthToken(null);
        setUserType(null);
        setUserName(null);
        localStorage.removeItem('MCToken');
        localStorage.removeItem('MCROLE');
        localStorage.removeItem('MCUSER');
        navigate('/login');
    }
    
    const handleClickAdmin = () => {
        setOpenAdmin(!openAdmin);
    };
    
    const handleDrawerOpen = () => {
        //user not authenticated go to login
        if (!authToken) {
            navigate('/login');
            return;
        }
        setOpen(true);
    };
    
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const StyledDrawer = styled(Drawer)(({ theme }) => ({
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.easeInOut,
            duration: 2000,
          }),
          overflowX: 'hidden',
          transform: open ? 'translateX(0)' : 'translateX(-100%)',
        },
    }));

    const StyledDiv = styled('div')({
        display: 'flex',
    });
    
    const StyledTitle = styled(Typography)(({ theme }) => ({
        fontWeight: 'bold',
        padding: theme.spacing(2),
    }));

    const StyledPage = styled('div')(({ theme }) => ({
        backgroundColor: '#f9f9f9',
        width: open ? `calc(100% - ${drawerWidth}px)` : '100%',
        marginLeft: open ? drawerWidth : 0,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        padding: theme.spacing(3),
    }));

    const StyledAppBar = styled(AppBar)(({ theme }) => ({
        width: open ? `calc(100% - ${drawerWidth}px)` : '100%',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }));
      
    const StyledMenuButton = styled(IconButton)(({ theme }) => ({
        marginRight: 2
    }));

    const StyledLoginButton = styled(Button)(({ theme }) => ({
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    }));

    return (
        <StyledDiv>
            <StyledAppBar elevation={0}>
                <Toolbar>
                    <StyledMenuButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerOpen}
                    >
                        <MenuIcon />
                    </StyledMenuButton>
                    <Hidden smDown>
                        <Typography variant="h6">
                            Mi Campito
                        </Typography>
                    </Hidden>
                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {userName ? (
                            <>
                            <Hidden smDown>
                                <Typography variant="h6" component="div" sx={{ paddingRight: 2 }}>
                                    {userName}
                                </Typography>
                            </Hidden>

                            {/*<Avatar alt="User Avatar" src="/static/images/avatar/1.jpg" />*/}
                            <StyledLoginButton
                                variant="contained"
                                color="error"
                                onClick={handleLogout}
                            >
                                Cerrar sesión
                            </StyledLoginButton>
                            </>
                        ) : (
                            <StyledLoginButton 
                            component={Link} 
                            to="/login" 
                            variant='contained'
                            color='secondary'
                            >
                            Iniciar sesión
                            </StyledLoginButton>
                        )}
                    </Box>
                </Toolbar>
            </StyledAppBar>
            <StyledDrawer 
                variant="temporary" 
                open={open}
                onClose={handleDrawerClose}
            >
                <div>
                    <StyledTitle variant="h5" color="primary">
                        Menu
                    </StyledTitle>
                </div>
                <AppSideMenu onClose={handleDrawerClose}/>
            </StyledDrawer>

            <StyledPage>
                <Toolbar component="div"/>
                {children}
            </StyledPage>
        </StyledDiv>
    );
}

export default Layout;