import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../../components/authContext';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Stepper, Step, StepLabel, Hidden } from '@mui/material';
import SearchWorker from './searchWorker';
import BoxProperty from './boxPropertys';
import BoxOverview from './boxOverview';
import BoxResult from './boxResult';

const steps = [
  'Buscar Trabajador',
  'Propiedades',
  'Resumen Operacion',
  'Resultados'
];

const AddBoxLayout = () => {
  const navigate = useNavigate();
  const { authToken } = useContext(AuthContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [worker, setWorker] = React.useState(null);
  const [variety, setVariety] = useState('');
  const [numBoxes, setNumBoxes] = useState(1);
  const [damages, setDamages] = useState({
      picadura: false,
      pudricion: false,
      partidura: false,
      palito: false,
      insecto: false,
      calibre: false,
      color: false,
      granizo: false,
  });


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSearchWorker = (workerInfo) => {
    setWorker(workerInfo);
    handleNext();
  }

  const handlePropertyChange = (varietyInfo, numBoxesInfo, damagesInfo) => {
    setVariety(varietyInfo);
    setNumBoxes(numBoxesInfo);
    setDamages(damagesInfo);
    handleNext();
  }

  const handleSuccess = () => {
    handleNext();
  }

  const handleReset = () => {
    setActiveStep(0);
    setWorker(null);
    setVariety('');
    setNumBoxes(1);
    setDamages({
      picadura: false,
      pudricion: false,
      partidura: false,
      palito: false,
      insecto: false,
      calibre: false,
      color: false,
      granizo: false,
    });
  }

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Hidden smDown >
        <Typography variant="h4" component="h1" gutterBottom>
          Agregar Cajas
        </Typography>
      </Hidden>
      
      <Stepper activeStep={activeStep} alternativeLabel sx={{ marginBottom: theme => theme.spacing(0) }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>
            <Hidden smDown >
              {label}
            </Hidden>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {/* Renderizar el contenido del paso actual */}
      {activeStep === 0 && <SearchWorker onWorkerFound={handleSearchWorker} />}
      {activeStep === 1 && <BoxProperty onPropertyChange={handlePropertyChange} onBack={handleBack}/>}
      {activeStep === 2 && <BoxOverview onBack={handleBack} onSuccess={handleSuccess} caja={{
          worker: worker,
          variety: variety,
          numBoxes: numBoxes,
          damages: damages
        }}
      />}
      {activeStep === 3 && <BoxResult onBack={handleBack} onReset={handleReset} caja={{
          worker: worker,
          variety: variety,
          numBoxes: numBoxes,
          damages: damages
        }}
      />}
    </Box>
  );
};

export default AddBoxLayout;
