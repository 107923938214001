import React, { useContext, useState }from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthContext } from '../components/authContext';
import Alert from '@mui/lab/Alert';
import { useNavigate } from 'react-router-dom';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https:/micampito.cl/">
        Mi Campito
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {
    const { authToken, setAuthToken, userType, setUserType, userName, setUserName } = useContext(AuthContext); // Agrega esto
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setEmailError(false);
        setPasswordError(false);
        setErrorMessage(null);
        const data = new FormData(event.currentTarget);
      
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/user/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            identifier: data.get('email'),
            password: data.get('password'),
          }),
        });
      
        if (response.ok) {
          const { token, role, username } = await response.json();
          setAuthToken(token);
          setUserType(role);
          setUserName(username);
          // Redirigir a la página de inicio
          localStorage.setItem('MCToken', token);
          localStorage.setItem('MCROLE', role);
          localStorage.setItem('MCUSER', username);

          navigate('/operation/summary');
        } else {
          switch (response.status) {
            case 401:
            case 404:
              setErrorMessage('Usuario o contraseña invalidos');
              setEmailError(true);
              setPasswordError(true);
              break;
            case 500:
              setErrorMessage('Error del servidor');
              break;
            default:
              setErrorMessage('Error: ' + response.status.toString());
          }
        }
      };

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Iniciar Sesión
          </Typography>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              error={emailError}
              helperText={emailError ? "Invalid email" : ""}
              margin="normal"
              required
              fullWidth
              id="email"
              label="correo electronico"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              error={passwordError}
              helperText={passwordError ? "Invalid password" : ""}
              margin="normal"
              required
              fullWidth
              name="password"
              label="contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Recuerdame"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Iniciar Sesión
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Olvido la Contraseña?
                </Link>
              </Grid>
                <Grid item>
                {/* <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link> */}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  );
}