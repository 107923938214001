import React, {useEffect,useState}from 'react';
import dayjs from 'dayjs';
import { Box, Grid, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

const CustomDateSelector = ({ text ,onDateSelected, currentDate }) => {
    const [selectedDate, setSelectedDate] = useState(dayjs(currentDate));

    useEffect(() => {
        if (selectedDate && selectedDate.isValid()) {
          console.log("Component " + text + " date:");
          console.log(selectedDate);
          setTimeout(() => {
            onDateSelected(selectedDate);
          }, 1000); // Delay of 1 second
        }
      }, [selectedDate]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleActualize = () => {
        if(!selectedDate){
            console.log("date is null");
            return;
        }

        if (selectedDate.isValid()) {
            console.log("Component " + text + " date:");
            console.log(selectedDate);
            onDateSelected(selectedDate);
        }
        else{
            console.log("date not valid");
        }
    }

    return (
        <Box margin={1} sx={{display:'flex', justifyContent:'space-around', flexWrap:'wrap'}}>
            <Typography marginRight={1} paddingTop={1}>
                {text}:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    format='DD/MM/YYYY'
                    slotProps={{ 
                        textField: { 
                            size: 'small',
                            variant: 'standard',
                            sx: { width: 130, paddingTop: 1 },
                        },
                        openPickerButton: { color: 'secondary' },
                    }}
                    color="secondary"
                    value={selectedDate}
                    onChange={handleDateChange}
                />
            </LocalizationProvider>

            <Box margin={1}>
                <Button color='secondary' variant='contained' endIcon={<RefreshIcon />} onClick={handleActualize}>
                    Actualizar
                </Button>
            </Box>
        </Box>
    );
};

export default CustomDateSelector;
