// AppSideMenu.js

import { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText, Collapse, List } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EngineeringIcon from '@mui/icons-material/Engineering';
import StoreIcon from '@mui/icons-material/Store';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import PeopleIcon from '@mui/icons-material/People';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { AuthContext } from './authContext';
import { set } from 'date-fns';

const AppSideMenu = (props) => {
  const { authToken, setAuthToken, userType, setUserType, userName, setUserName } = useContext(AuthContext); // Agrega esto
  const [openSectionId, setOpenSectionId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();


  const handleSectionClick = (id) => {
    setOpenSectionId(openSectionId === id ? null : id);
  };

  const handleNav = (path, role) => {

    //user not authenticated go to login
    if (!authToken) {
      navigate('/login');
      props.onClose(); // llama a la función de callback
      return;
    }

    if (role.includes(userType)) {
      navigate(path);
      console.log(path);
      props.onClose(); // llama a la función de callback
    } else {
      console.log('No tienes permiso para acceder a esta página');
      navigate(location.pathname); // redirige al usuario a la ruta actual
    }
  };

  const adminRoutes = [
    { path: "/admin/userDashboard", role: ["Admin"], icon: <PeopleIcon />, text: "Usuarios" },
    { path: "/admin/varietyDashboard", role: ["Admin"], icon: <FastfoodIcon />, text: "Variedades" },
    { path: "/admin/companyDashboard", role: ["Admin"], icon: <StoreIcon />, text: "Empresas" },
    { path: "/admin/tractorDashboard", role: ["Admin"], icon: <AgricultureIcon />, text: "Tractores" },
    { path: "/admin/workerDashboard", role: ["Admin", "Supervisor", "BookKeeper" ], icon: <EngineeringIcon />, text: "Trabajadores" },
  ];

  const adminRoutesDisplayRoles = ["Admin", "Supervisor", "BookKeeper" ];

  const reportRoutes = [
    { path: "/report/dashboard", role: ["Admin", "Supervisor"], icon: <PeopleIcon />, text: "Panel" },
    { path: "/report/workerdetail", role: ["Admin", "Supervisor"], icon: <PeopleIcon />, text: "Resumen Cajas" },
  ];

  const reportRoutesDisplayRoles = ["Admin", "Supervisor"];

  const operationRoutes = [
    { path: "/operation/summary", role: ["Admin", "Supervisor", "BookKeeper"], icon: <PeopleIcon />, text: "Resumen" },
    { path: "/operation/dashboard", role: ["Admin", "Supervisor", "BookKeeper"], icon: <PeopleIcon />, text: "Tabla Trabajadores" },
    { path: "/operation/workerView", role: ["Admin", "Supervisor", "BookKeeper"], icon: <PeopleIcon />, text: "Vista Trabajador" },
  ];

  const operationRoutesDisplayRoles = ["Admin", "Supervisor", "BookKeeper" ];

  const dispatcherRoutes = [
    { path: "/dispatcher/dashboard", role: ["Admin", "Supervisor", "Despachador"], icon: <PeopleIcon />, text: "Panel" },
    //{ path: "/dispatcher/orchard", role: ["Admin", "Supervisor", "Despachador"], icon: <PeopleIcon />, text: "Huerto" },
    //{ path: "/dispatcher/truck", role: ["Admin", "Supervisor", "Despachador"], icon: <PeopleIcon />, text: "Camion" },
    //{ path: "/dispatcher/inventory", role: ["Admin","Supervisor", "Despachador"], icon: <PeopleIcon />, text: "Inventario" },
  ];

  const dispatcherRoutesDisplayRoles = ["Admin", "Supervisor", "Despachador" ];

  const workerRoutes = [
    { path: "/worker/dashboard", role: ["Admin", "Supervisor", "Despachador"], icon: <PeopleIcon />, text: "Panel" },
    { path: "/worker/add", role: ["Admin", "Supervisor", "Despachador"], icon: <PeopleIcon />, text: "Agregar" },
  ];

  const workerRoutesDisplayRoles = ["Admin", "Supervisor", "BookKeeper" ];

  const menuSectiones = [
    { id: 1, name: "Administracion", icon: <ManageAccountsIcon /> ,  "routes": adminRoutes, "displayRoles": adminRoutesDisplayRoles },
    { id: 2, name: "Reportes", icon: <PeopleIcon /> ,"routes": reportRoutes, "displayRoles": reportRoutesDisplayRoles },
    { id: 3, name: "Operacion", icon: <PeopleIcon /> ,"routes": operationRoutes, "displayRoles": operationRoutesDisplayRoles },
    { id: 4, name: "Despacho", icon: <PeopleIcon /> ,"routes": dispatcherRoutes, "displayRoles": dispatcherRoutesDisplayRoles },
    { id: 5, name: "Trabajador", icon: <EngineeringIcon /> ,"routes": workerRoutes, "displayRoles": workerRoutesDisplayRoles },
  ]

  return (
    <>
        { menuSectiones.map((section, index) => (
            (section.displayRoles.includes(userType)) && (
              <>
                <ListItem button onClick={() => handleSectionClick(section.id)}>
                  <ListItemIcon>
                    {section.icon}
                  </ListItemIcon>
                  <ListItemText primary={section.name} />
                  {openSectionId === section.id ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openSectionId === section.id} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {section.routes.map((route, index) => (
                      route.role.includes(userType) && (
                        <ListItem button style={{ paddingLeft: '2rem' }} onClick={() => handleNav(route.path, route.role)} key={index}>
                          <ListItemIcon>
                            {route.icon}
                          </ListItemIcon>
                          <ListItemText primary={route.text} />
                        </ListItem>
                      )
                    ))}
                  </List>
                </Collapse>
              </>
            )
        ))}
    </>
  );
};



export default AppSideMenu;