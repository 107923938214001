/*
Usando @mui/material, crea un componente que despligue una tarjeta con la información de un camión.
mostrando empresa, bins, totes y esponjas.
esta informacion se extrea mediante un post a un servidor.
el componente se llama truckSummaryCard
Debe tener un titulo usando una tipografia centrada de tamaño h6 que diga: "Carga Camion"
*/


import React, { useEffect, useState, useContext} from 'react';
import { AuthContext } from '../authContext';
import {Box, Card, CardContent, Typography, CircularProgress, Button } from '@mui/material';

const OrchardTotalSummaryCard = ({dateInfoString}) => {
    const { authToken } = useContext(AuthContext);
    const [boxCount, setBoxCount] = useState(0);
    const [binsCount, setBinsCount] = useState(0);
    const [workerCount, setWorkerCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
    

        const fetchWorkers = async () => {
            setIsLoading(true);
            const payload = {
                dateInfo: dateInfoString
            };
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/boxes/getBoxCountOnDate`, {
                    method: 'POST',
                    headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authToken}`
                    },
                    body: JSON.stringify(payload),
            });

            if (response.ok) {
                    const boxes = await response.json();
                    let totalBoxes = 0;
                    let totalWorkers = 0;
                    boxes.map((box) => { 
                        totalBoxes += box.Cajas;
                        totalWorkers += 1;
                    })
                    setBoxCount(totalBoxes);
                    const bins = Math.ceil(totalBoxes / 24); // Calculate the ceiling value of bins
                    setBinsCount(bins);
                    setWorkerCount(totalWorkers);
                    setIsLoading(false);
            } else {
                    // Manejar el error
                    setIsLoading(false);
            }
        };
    
        fetchWorkers();
      }, [authToken, dateInfoString]);

    return (
        <Card sx={{maxWidth:250, margin:2}}>
            <CardContent>
                <Typography variant="h6" align="center" color="primary" sx={{marginBottom:2}}>
                    Resumen Produccion Huerto
                </Typography>
                {isLoading ? (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Typography variant="body1">Fecha: {dateInfoString}</Typography>
                        <Typography variant="body1">Bins: {binsCount}</Typography>
                        <Typography variant="body1">Totes: {boxCount}</Typography>
                        <Typography variant="body1">Personas: {workerCount}</Typography>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default OrchardTotalSummaryCard;
