import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../components/authContext';
import { Box, TextField , Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import Alert from '@mui/lab/Alert';




const NewTractorForm = () => {
    const [tractorColor, setTractorColor] = useState('');
    const [tractorPatente, setTractorPatente] = useState('');
    const [tractorCapacidad, setTractorCapacidad] = useState('');
    const { authToken } = useContext(AuthContext);
    const [colorError, setColorError] = useState(false);
    const [patenteError, setPatenteError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [confirmAdd, setConfirmAdd] = useState(false);
    const navigate = useNavigate();


    const handleSubmit = (e) => {
        e.preventDefault();

        console.log("tractorColor: " + tractorColor);
        console.log("tractorPatente: " + tractorPatente);
        console.log("tractorCapacidad: " + tractorCapacidad);
        
        if (!tractorColor) {
            setColorError(true);
            setErrorMessage("Se requiere agregar un color al tractor");
            return;
        } else {
            setColorError(false);
        }

        if (!tractorPatente) {
            setPatenteError(true);
            setErrorMessage("Se requiere agregar una patente al tractor");
            return;
        } else if (!/^[A-Za-z0-9]{1,6}$/.test(tractorPatente)) {
            setPatenteError(true);
            setErrorMessage("La patente debe tener máximo 6 caracteres alfanuméricos");
            return;
        } else {
            setPatenteError(false);
        }

        // Convertir las letras de la patente a mayúsculas
        const tractorPatenteUpperCase = tractorPatente.toUpperCase();

        // Create the JSON payload
        const payload = {
            tractor_color: tractorColor,
            tractor_patente: tractorPatenteUpperCase,
            tractor_capacidad: tractorCapacidad
        };

        console.log(payload);

        // Make the HTTP request
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/tractor/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(payload)
        })
        .then(response => {
            if (response.ok) {
                // Redirect to tractorDashboard on success
                setConfirmAdd(true);
            } else {
                switch (response.status) {
                    case 401:
                        setErrorMessage('Sesion Expirada');
                        break;
                    case 403:
                        setErrorMessage('No autorizado');
                        break;
                    case 400:
                        setErrorMessage('Faltan campos requeridos');
                        break;
                    case 422:
                        setErrorMessage('Faltan campos requeridos');
                        break;
                    case 500:
                        setErrorMessage('Error del servidor');
                        break;
                    default:
                        setErrorMessage('Error: ' + response.status.toString());
                }
                console.error('Error:', response.status);
            }
        })
        .catch(error => {
            // Handle network error
            console.error('Error:', error);
        });
    };

    const MyStyledTitleTypography = styled(Typography)(({ theme }) => ({
        fontWeight: 'bold',
        fontSize: 20,
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    }));

    const MyStyledTypography = styled(Typography)(({ theme }) => ({
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    }));

    // Styled button
    const MyStyledButton = styled(Button)(({ theme }) => ({
        maxWidth: '200px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        alignSelf: 'center',
    }));

    const MyStyledBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '400px',
        margin: '0 auto',
    }));

    return (
        <div>
            {!confirmAdd ? (
                <form onSubmit={handleSubmit}>
                    <Box display="flex" flexDirection="column"  maxWidth="400px" margin="0 auto">
                        <MyStyledTitleTypography variant="h6" component="div">
                            Agregar Nuevo Tractor
                        </MyStyledTitleTypography>
                        {errorMessage && 
                            <MyStyledBox>
                                <Alert severity="error">{errorMessage}</Alert>
                            </MyStyledBox>
                        }

                        <MyStyledTypography component="div">
                        Ingrese el color del tractor:
                        </MyStyledTypography>
                        <TextField
                            label="Color del Tractor"
                            value={tractorColor}
                            onChange={(e) => {
                                setTractorColor(e.target.value);
                            }}
                            required
                        />
                        <MyStyledTypography component="div">
                        Ingrese la patente de la maquina:
                        </MyStyledTypography>
                        <TextField
                            label="Patente del Tractor"
                            value={tractorPatente}
                            onChange={(e) => {
                                const input = e.target.value;
                                const regex = /^[a-zA-Z0-9]{0,6}$/; // Regex para aceptar solo letras y números con un máximo de 6 caracteres
                                if (regex.test(input)) {
                                    setTractorPatente(input);
                                }
                            }}
                            required
                            error={patenteError}
                            helperText={patenteError && "La patente debe tener máximo 6 caracteres alfanuméricos"}
                        />
                        <MyStyledTypography component="div">
                        Ingrese la capacidad en bins del tractor:
                        </MyStyledTypography>
                        <TextField
                            label="Capacidad del Tractor"
                            value={tractorCapacidad}
                            onChange={(e) => {
                                setTractorCapacidad(e.target.value);
                            }}
                            inputProps={{
                                type: 'number',
                                pattern: '[0-9]*'
                            }}
                        />
                        <MyStyledButton type="submit" variant="contained" color="secondary">
                            Agregar Tractor
                        </MyStyledButton>
                    </Box>
                </form>
            ) : (
                <Box display="flex" flexDirection="column"  maxWidth="400px" margin="0 auto"> 
                    <MyStyledTypography variant="h6" align='center'>
                        El item "{tractorColor}" ha sido agregado a la tabla de tractores.
                    </MyStyledTypography>
                    <MyStyledButton
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate("/admin/tractorDashboard")}
                    >
                        Volver a la tabla
                    </MyStyledButton>
                </Box>
            )}
        </div>
    );
};

export default NewTractorForm;
