import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../components/authContext';
import { Grid, Select, MenuItem, FormControl, InputLabel, TextField, Box, Typography, Button } from '@mui/material';

const Add2Truck_Items = ({
    bins,
    setBins,
    totes,
    setTotes,
    esponjas,
    setEsponjas,
    variety,
    setVariety,
    empresa,
    setEmpresa,
    onNext,
}) => {
    const { authToken } = useContext(AuthContext);
    const [varietyOptions, setVarietyOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/variety/getActiveVarietiesNames`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setVarietyOptions(data);
            })
            .catch(error => console.log(error));
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/company/getAll`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setCompanyOptions(data);
            })
            .catch(error => console.log(error));
        

        setIsLoading(false);
    }, []);

    const handleReset = () => {
        setBins(0);
        setTotes(0);
        setEsponjas(0);
        setVariety('');
    };

    const handleMas1Bin = () => {
        setBins(bins + 1);
        setTotes(totes + 24);
    };

    const handleMas4Bins = () => {
        setBins(bins + 4);
        setTotes(totes + 96);
    };

    const handleMasCorona = () => {
        setTotes(totes + 8);
    };

    if (isLoading) {
        return <p>Cargando...</p>;
    }

    return (
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Grid container margin={1} spacing={2} xs={12} sm={6}>
            <Grid item xs={12}>
                    <Typography variant="h6" color="primary" sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}>Cargar Camion</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body1" sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        Bins
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        type="number"
                        value={bins}
                        onChange={event => setBins(event.target.value)}
                        sx={{ m: theme => theme.spacing(0), minWidth: '100%' }}
                        size="small"
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body1" sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        Totes
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        type="number"
                        value={totes}
                        onChange={event => setTotes(event.target.value)}
                        sx={{ m: theme => theme.spacing(0), minWidth: '100%' }}
                        size="small"
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body1" sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        Esponjas
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        type="number"
                        value={esponjas}
                        onChange={event => setEsponjas(event.target.value)}
                        sx={{ m: theme => theme.spacing(0), minWidth: '100%' }}
                        size="small"
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body1" sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        Variedad
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Select
                        color='primary'
                        value={variety || ''}
                        onChange={event => setVariety(event.target.value)}
                        sx={{ m: theme => theme.spacing(0), minWidth: '100%' }}
                        size="small"
                    >
                        { varietyOptions.map((variety) => (
                            <MenuItem key={varietyOptions.indexOf(variety)} value={variety}>{variety}</MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body1" sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        Empresa
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Select
                        color='primary'
                        value={empresa || ''}
                        onChange={event => setEmpresa(event.target.value)}
                        sx={{ m: theme => theme.spacing(0), minWidth: '100%' }}
                        size="small"
                    >
                        { companyOptions.map((company) => (
                            <MenuItem key={company.empresas_id} value={company}>{company.empresa_nombre}</MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
                <Grid item container xs={12} sx={{ display:'flex', flexWrap:'wrap', justifyContent:'center'}}>
                    <Button color='secondary' variant='contained' sx={{margin:1}} onClick={() => handleMas1Bin()}>
                        + 1 Bin
                    </Button>
                    <Button color='secondary' variant='contained' sx={{margin:1}} onClick={() => handleMas4Bins()}>
                        + 4 Bins
                    </Button>
                    <Button color='secondary' variant='contained' sx={{margin:1}} onClick={() => handleMasCorona()}>
                        + 1 Corona
                    </Button>
                    <Button color='error' variant='contained' sx={{margin:1}} onClick={() => handleReset()}>
                        Reset
                    </Button>
                </Grid>
                <Grid item container xs={12} sx={{ display:'flex', flexWrap:'wrap', justifyContent:'center'}}>
                    <Button variant='contained' sx={{margin:1, width:'100%'}} disabled={!empresa} onClick={() => onNext()}>
                        Siguiente
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Add2Truck_Items;
