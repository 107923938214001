import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../components/authContext';
import { Container, Card, CardContent, TextField , Typography, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const TransactionCard = ({ Info, onUpdate}) => {
    const { authToken } = useContext(AuthContext);
    var time = Info.timestamp.slice(11, 19);

    const handleDeleteDialog = (despacho_id) => {
        if (window.confirm("¿Estas seguro que quieres eliminar esta transaccion?")) {
            console.log("Si eliminar");
            actualDelete();
        }
    };

    const actualDelete = async () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/reciveTractor/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({ idReciveTractor: Info.idReciveTractor }) // Add the datInfo field with the current date
        })
            .then(response => {
                if (response.ok) {
                    onUpdate(); // Call the onUpdate function if the response is ok
                }
                return response.json();
            })
            .catch(error => console.log(error));
    };

    return (
        <Card sx={{ minWidth: 250, maxheight: 100 }}>
            <CardContent
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                    alignContent: "center",
                }}
            >
                <Tooltip title="Eliminar">
                    <IconButton
                        onClick={() =>
                            handleDeleteDialog(Info.idReciveTractor)
                        }
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                <Typography variant="body1">Hora: {time}</Typography>
                <Typography variant="body1">Tractor: {Info.tractorName}</Typography>
                <Typography variant="body1">B: {Info.bins}</Typography>
                <Typography variant="body1">T: {Info.totes}</Typography>
            </CardContent>
        </Card>
    );
};

const ReciveTractorTransactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [update, setUpdate] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { authToken } = useContext(AuthContext);
    const navigate = useNavigate();

    var today = new Date();
    var offset = today.getTimezoneOffset();
    today = new Date(today.getTime() - (offset * 60 * 1000));
    var dateInfo = today.toISOString().slice(0, 10);

    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/reciveTractor/getOnDate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({ dateInfo: dateInfo }) // Add the datInfo field with the current date
        })
            .then(response => response.json())
            .then(data => {
                setTransactions(data);
                setIsLoading(false)
            })
            .catch(error => console.log(error));
    }, [update]);

    const handleUpdate = () => {
        setUpdate(!update);
    }

    if(isLoading){
        return (
            <Container>
                <Typography variant="h6" component="div">
                    Recepcion Tractores
                </Typography>
                <Typography variant="body1" component="div">
                    Cargando...
                </Typography>
            </Container>
        );
    }

    return (
        <Container>
            <Typography variant="h6" component="div">
                Recepcion Tractores
            </Typography>
            {transactions.length === 0 ? (
                <Typography variant="body1" component="div">
                    Sin Datos
                </Typography>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            {transactions.map((transaction) => (
                                <TableRow key={transaction.idReciveTractor}>
                                    <TableCell>
                                        <TransactionCard Info={transaction} onUpdate={handleUpdate}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Container>
    );
};

export default ReciveTractorTransactions;