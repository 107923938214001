import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../components/authContext';
import { Container, InputAdornment, Box, TextField , Typography, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useNavigate } from 'react-router-dom';

const UserDashboard = () => {
    const [users, setUsers] = useState([]);
    const { authToken } = useContext(AuthContext);
    const navigate = useNavigate();
    
    useEffect(() => {
        const fetchUsers = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/user/getAllUsers`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
            });

            if (response.ok) {
                const users = await response.json();
                setUsers(users);
            } else {
                // Manejar el error
            }
        };

        fetchUsers();
    }, [authToken]);

    const handleDelete = async (itemId, itemName, itemType) => {
        navigate(`/deleteItem/${itemId}/${itemName}/${itemType}`);
    };

    return (
        <Container>
            <Typography variant="h6" component="div">
                Administración de Usuarios
            </Typography>
            <Toolbar>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Box>
                        <TextField
                            label="Buscar usuarios"
                            variant="outlined"
                            size="small"
                            style={{ marginRight: '1rem' }}
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton color="primary" onClick={() => console.log('Buscar')}>
                                    <SearchIcon />
                                </IconButton>
                                </InputAdornment>
                            ),
                            }}
                        />
                        <IconButton color="primary" onClick={() => console.log('Agregar filtro')}>
                            <FilterListIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={() => console.log('Agregar usuario')}>
                            <PersonAddIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Toolbar>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.username}>
                                <TableCell>{user.username}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.rol}</TableCell>
                                <TableCell>
                                    <IconButton color="primary" onClick={() => console.log('Edit')}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                {/*
                                <TableCell>
                                    <IconButton color="error" onClick={() => handleDelete(user.email, user.username,"user")}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                                */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default UserDashboard;