import React, {useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../components/authContext';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar, GridToolbarContainer, esES} from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';

function CustomToolbar({startDate, endDate}){
  return (
    <GridToolbarContainer>
        <GridToolbar csvOptions={{
        fileName: 'Resumen_Cajas_' + startDate + '_' + endDate,
        utf8WithBom: true,
        }}
    />
    </GridToolbarContainer>
  );
}

// create enumarat for state
const State = Object.freeze({
  Loading: 0,
  NoData: 1,
  Display: 2,
  Error: 3
});

export default function WorkerDetailSummary({dateStart, dateEnd}) {
  const navigate = useNavigate();
  const { authToken } = useContext(AuthContext);
  const [rows, setRows] = React.useState([]);
  const [isState, setIsState] = useState(State.Loading);
  const [columns, setColumns] = useState(null);
  const [initialState, setInitialState] = useState(null);
  const isXsScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');



  let width_value = 150;
  let colum_visivility= true;

  useEffect(() => {
    setIsState(State.Loading);

    if(!dateStart){
      return;
    }

    if(!dateEnd){
      return;
    }

    console.log("Start Date: ");
    console.log(dateStart);
    const startDateWithTimeSetToNoon = new Date(dateStart);
    startDateWithTimeSetToNoon.setHours(12, 0, 0, 0);
    const startDateInfoString = startDateWithTimeSetToNoon.toISOString().split('T')[0];
    setStartDate(startDateInfoString);
    console.log(startDateInfoString);

    console.log("End Date: ");
    console.log(dateEnd);
    const endDateWithTimeSetToNoon = new Date(dateEnd);
    endDateWithTimeSetToNoon.setHours(12, 0, 0, 0);
    const endDateInfoString = endDateWithTimeSetToNoon.toISOString().split('T')[0];
    setEndDate(endDateInfoString);
    console.log(endDateInfoString);

    const fetchWorkers = async () => {
      const payload = {
        startDate: startDateInfoString,
        endDate: endDateInfoString
      };
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/boxes/getDetailBoxSummary`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`
          },
          body: JSON.stringify(payload),
      });

      if (response.ok) {
          const boxes = await response.json();

          if(boxes.length === 0){
            setIsState(State.NoData)
            return;
          }

          // extract columns from the first row
          const columns = Object.keys(boxes[0]).map((key) => {
            if(key === 'id'){
                colum_visivility = false;
            }else{
                colum_visivility = true;
            }

            let keyHeader = key;

            if(key === 'Nombre'){
                width_value = 150;
                keyHeader = 'Nombre';
            }
            else if(key === 'Apellido_Paterno'){
                width_value = 120;
                keyHeader = 'Apellido';
            }
            else if(key === 'Apellido_Materno'){
                width_value = 120;
                keyHeader = 'Apellido';
            }
            else if(key === 'Rut'){
                width_value = 100;
                keyHeader = 'Rut';
            }
            else if(key === 'total'){
              width_value = 100;
              keyHeader = 'Total';
            }
            else {
                width_value = 100;
            }

            return {
            field: key,
            headerName: keyHeader,
            width: width_value,
            hide: !colum_visivility,
            };
          });
          
          setColumns(columns);

          //columnVisibilityModel
          let columnVisibilityModel = {
            checkbox: false,
          }
          
          columns.map((column) => {
            columnVisibilityModel = {
              ...columnVisibilityModel,
              [column.field]: true,
            };
          });

          columnVisibilityModel.id = false;

          const initialState_data = {
            columns: {
                columnVisibilityModel, 
            },
            pagination: { paginationModel: { pageSize: 10 } },
          }

          setInitialState(initialState_data);

          let rows = [];
          boxes.map((row) => { 
            rows.push(
                // create object from the columns
                Object.keys(row).reduce(
                    (object, key) => ({ ...object, [key]: row[key] }),
                    {}
                )
            )
          })
          setRows(rows);
          setIsState(State.Display)
      } else {
          // Manejar el error
          setIsState(State.Error)
      }
    };

    fetchWorkers();
  }, [authToken, dateStart, dateEnd]);


  if(isState === State.Loading) {
    return <p>Cargando...</p>;
  }

  if(isState === State.NoData) {
    return <p>No hay datos para mostrar</p>;
  }

  if(isState === State.Error) {
    return <p>Hubo un error al cargar los datos</p>;
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
            //checkboxSelection
            disableRowSelectionOnClick
            density="compact"
            columns = {columns}
            rows = {rows}
            initialState = {initialState}
            slots={{ toolbar: () => <CustomToolbar startDate={startDate} endDate={endDate}/> }} 
            sx={{ '--DataGrid-overlayHeight': '300px' }}
            pageSizeOptions={[5, 10, 25]}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}

        />
    </div>
  );
}