
import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../components/authContext';
import { Avatar, Box, TextField, Typography, Button, Hidden } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/lab/Alert';
import { Grid } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const NewWorkerForm = () => {
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [rut, setRut] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [phone, setPhone] = useState('');
    const [age, setAge] = useState('');
    const { authToken } = useContext(AuthContext);
    const navigate = useNavigate();
    const [nameError, setNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [middleNameError, setMiddleNameError] = useState(false);
    const [rutError, setRutError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [confirmAdd, setConfirmAdd] = useState(false);
    

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handleMiddleNameChange = (event) => {
        setMiddleName(event.target.value);
    };

    const handleRutChange = (event) => {
        const value = event.target.value;
        const regex = /^[0-9-]*$/; // Regular expression to allow only numbers and hyphens
        if (regex.test(value)) {
            setRut(value);
        }
    };

    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
    };

    const handlePhoneChange = (event) => {
        const value = event.target.value;
        const regex = /^[0-9]*$/; // Regular expression to allow only numbers and hyphens
        if (regex.test(value) && value.length <= 11) {
            setPhone(value);
        }
    };

    const handleAgeChange = (event) => {
        setAge(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let noreq = false;
        //reste all errors
        setNameError(false);
        setLastNameError(false);
        setMiddleNameError(false);
        setRutError(false);
        setAddressError(false);
        setCityError(false);

        // Reset error message
        setErrorMessage(null);

        // Check if field name is empty
        if (!name) {
            setNameError(true);
            noreq = true;
        }

        // check if field last name is empty
        if (!lastName) {
            setLastNameError(true);
            noreq = true;
        }

        // check if field middle name is empty
        if (!middleName) {
            setMiddleNameError(true);
            noreq = true;
        }

        // check if field rut is empty
        if (!rut) {
            setRutError(true);
            noreq = true;
        }

        // check if field address is empty
        if (!address) {
            setAddressError(true);
            noreq = true;
        }

        // check if field city is empty
        if (!city) {
            setCityError(true);
            noreq = true;
        }

        // Return if there is an error
        if (noreq) {
            setErrorMessage('Falta campos requeridos');
            return;
        }

        let payload = {
            trabajador_nombre: name,
            trabajador_apellido_paterno: lastName,
            trabajador_apellido_materno: middleName,
            trabajador_rut: rut,
            trabajador_direccion: address,
            trabajador_comuna: city,
            trabajador_telefono: phone,
        }


        // Add worker to the database
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/worker/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(payload)
        })
        .then(response => {
            if (response.ok) {
                // Redirect to companyDashboard on success
                setConfirmAdd(true);
                return;            
            } else {
                switch (response.status) {
                    case 403:
                        setErrorMessage('No autorizado');
                        return;
                    case 400:
                        setErrorMessage('Falta campos requeridos');
                        return;
                    case 422:
                        setErrorMessage('Error agregando trabajador');
                        break;
                    case 500:
                        setErrorMessage('Error en el servidor');
                        return;
                    default:
                        setErrorMessage('Error: ' + response.status.toString());
                  }
            }
        })
        .catch(error => {
            // Handle network error
            console.error('Error:', error);
            return;
        })


    };

    return (
        <div>
            {!confirmAdd  ? (
                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                    <Hidden smDown>
                        <Avatar sx={{ m: 2, bgcolor: 'secondary.main' }}>
                            <PersonAddIcon />
                        </Avatar>
                    </Hidden>

                    <Typography variant="h6" gutterBottom marginBottom={3} textAlign="center">
                        Agregar Nuevo Trabajador
                    </Typography>

                    {errorMessage && (<Box marginBottom={3}><Alert severity="error">{errorMessage}</Alert></Box>)}

                        <Grid container marginBottom={3} spacing={3} xs={12} sm={9} md={6}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nombre"
                                    value={name}
                                    variant="outlined"
                                    size='small'
                                    onChange={handleNameChange}
                                    required
                                    error={nameError} 
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Apellido Paterno"
                                    value={lastName}
                                    variant="outlined"
                                    size='small'
                                    onChange={handleLastNameChange}
                                    required
                                    error={lastNameError}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Apellido Materno"
                                    value={middleName}
                                    variant="outlined"
                                    size='small'
                                    onChange={handleMiddleNameChange}
                                    required
                                    error={middleNameError}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="RUT"
                                    value={rut}
                                    variant="outlined"
                                    size='small'
                                    onChange={handleRutChange}
                                    required
                                    error={rutError}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Teléfono"
                                    value={phone}
                                    variant="outlined"
                                    size='small'
                                    onChange={handlePhoneChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    fullWidth
                                    label="Dirección"
                                    value={address}
                                    variant="outlined"
                                    size='small'
                                    onChange={handleAddressChange}
                                    required
                                    error={addressError}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    label="Comuna"
                                    value={city}
                                    variant="outlined"
                                    size='small'
                                    onChange={handleCityChange}
                                    required
                                    error={cityError}
                                />
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <Button fullWidth color="secondary" variant="contained" onClick={handleSubmit}>Agregar</Button>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Button fullWidth color="error" variant="contained" onClick={() => navigate("/admin/workerDashboard")}>Cancelar</Button>
                            </Grid>
                        </Grid>
                </Box>
            ):(
                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                    <Typography variant="h6" gutterBottom marginBottom={3}>
                        El trabajador {name} ha sido agregado a la tabla de trabajadores.
                    </Typography>
                    <Button color="secondary" variant="contained" onClick={() => navigate("/admin/workerDashboard")}>Volver a la tabla</Button>
                </Box>
            )}
        </div>
    );
};

export default NewWorkerForm;
