/*
Usando @mui/material, crea un componente que despligue una tarjeta con la información de un camión.
mostrando empresa, bins, totes y esponjas.
esta informacion se extrea mediante un post a un servidor.
el componente se llama truckSummaryCard
Debe tener un titulo usando una tipografia centrada de tamaño h6 que diga: "Carga Camion"
*/


import React, { useEffect, useState, useContext} from 'react';
import { AuthContext } from '../authContext';
import {Box, Card, CardContent, Typography, CircularProgress, Button } from '@mui/material';

const TractorReciveSummaryCard = ({dateInfoString}) => {
    const { authToken } = useContext(AuthContext);
    const [tractorsSummarys, setTractorsSummarys] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        setIsLoading(true);
        setTractorsSummarys([]);
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/reciveTractor/getCountOnDate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({ dateInfo: dateInfoString }) // Add the datInfo field with the current date
        })
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data) && data.length >= 1) {
                    //for each tractor, add the total bins and totes
                    let total_bins = 0;
                    let total_totes = 0;
                    data.forEach((summary) => {
                        total_bins += parseInt(summary.total_bins);
                        total_totes += parseInt(summary.total_totes);
                    });
                    data.push({tractor_color: 'Total', total_bins: total_bins, total_totes: total_totes});
                    setTractorsSummarys(data);
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });

    }, [authToken, dateInfoString]);

    const handleNext = () => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % tractorsSummarys.length);
    };

    const handlePrevious = () => {
        setCurrentIndex(prevIndex => (prevIndex - 1 + tractorsSummarys.length) % tractorsSummarys.length);
    };

    return (
        <Card sx={{maxWidth:250, margin:2}}>
            <CardContent>
                <Typography variant="h6" align="center" color="primary" sx={{marginBottom:2}}>
                    Resumen Recepcion Tractores
                </Typography>
                {isLoading ? (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {tractorsSummarys.length === 0? (
                            <>
                                <Typography variant="body1">Fecha: {dateInfoString}</Typography>
                                <Typography variant="body1">Sin Informacion</Typography>
                            </>
                        ) : (
                            <>
                                <Typography variant="body1">Fecha: {dateInfoString}</Typography>
                                <Typography variant="body1">Tractor: {tractorsSummarys[currentIndex].tractor_color}</Typography>
                                <Typography variant="body1">Bins: {tractorsSummarys[currentIndex].total_bins}</Typography>
                                <Typography variant="body1">Totes: {tractorsSummarys[currentIndex].total_totes}</Typography>
                                <Box sx={{display:'flex', justifyContent:'space-around', marginTop:2, marginBottom:2}}>
                                    <Button sx={{margin:1}} variant="contained" onClick={handlePrevious}>Anterior</Button>
                                    <Button sx={{margin:1}} variant="contained" onClick={handleNext}>Siguiente</Button>
                                </Box>
                            </>
                        )}
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default TractorReciveSummaryCard;
