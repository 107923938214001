import React, { useEffect, useState, useContext} from 'react';
import { AuthContext } from '../../components/authContext';
import {Box, Typography, Hidden, Tab, Tabs, tabsClasses} from '@mui/material';
import SearchWorker from '../../components/workerSelector';
import WorkerViewSelectDates from './workerViews/workerViewSelectDates';
import WorkerViewTable from './workerViews/workerViewTable';

const OperationWorkerView = () => {
    const [activeTab, setActiveTab] = React.useState(0);
    const [selectedDateStart, setSelectedDateStart] = useState(new Date());
    const [selectedDateEnd, setSelectedDateEnd] = useState(new Date());
    const [worker, setWorker] = useState(null);

    useEffect(() => {
        console.log("OperationWorkerView");
        console.log(selectedDateStart);
    }, [selectedDateStart, selectedDateEnd]);

    const handleDateStartChange = (date) => {
        setSelectedDateStart(date);
    };

    const handleDateEndChange = (date) => {
        setSelectedDateEnd(date);
        console.log(date);
    };

    const handleWorkerFound = (worker) => {
        setWorker(worker);
        setActiveTab(2);
    }

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box sx={{ width: '100%' }} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Hidden smDown>
                <Typography variant="h6" color="primary">Vista Trabajador</Typography>
            </Hidden>
            <Box marginBottom={2}>
                <Tabs 
                    value={activeTab} 
                    onChange={handleTabChange} 
                    aria-label="Dispatcher Tabs"   
                    variant="scrollable"
                    scrollButtons
                    sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                    }}
                >
                    <Tab label="Cambiar Trabajador" />
                    <Tab label="Cambiar Fechas" />
                    <Tab label="Tabla" />
                    <Tab label="Agregar Cajas" />
                </Tabs>
            </Box>
            <Box margin={1} sx={{display:'flex', justifyContent:'space-around', flexWrap:'wrap'}}>
                <Typography marginRight={3}>Trabajador: {worker ? `${worker.trabajador_name} ${worker.trabajador_rut}` : 'No seleccionado'}</Typography>
                <Typography >Periodo: {selectedDateStart.toISOString().split('T')[0]} - {selectedDateEnd.toISOString().split('T')[0]}</Typography>
            </Box>
            {activeTab === 0 && <SearchWorker onWorkerFound={handleWorkerFound}/>}
            {activeTab === 1 && <WorkerViewSelectDates dateStart={selectedDateStart} dateEnd={selectedDateEnd} onDateStartChange={handleDateStartChange} onDateEndChange={handleDateEndChange}/>}
            {activeTab === 2 && <WorkerViewTable worker={worker} dateStart={selectedDateStart} dateEnd={selectedDateEnd} />}
            {activeTab === 3 && <p>Agregar Cajas</p>}
        </Box>
    );
};

export default OperationWorkerView;