import React, {useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../components/authContext';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, TextField, Typography, Button, Hidden, InputAdornment, IconButton} from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarExport, GridToolbarContainer, GridFilterOperato, esES} from '@mui/x-data-grid';
import AddBoxIcon from '@mui/icons-material/AddBox';
import useMediaQuery from '@mui/material/useMediaQuery';

function createData(id, worker_id, name, rut, supervisor, date, boxes) {
    return {
      id,
      worker_id,
      name,
      rut,
      supervisor,
      date,
      boxes,
    };
  }

const columns_data = [
    {field: 'id', headerName: '#', width: 100},
    {field: 'worker_id', headerName: 'Id', hide: true},
    {field: 'name', headerName: 'Nombre', width: 200},
    {field: 'rut', headerName: 'Rut', width: 150},
    {field: 'supervisor', headerName: 'Supervisor', width: 150},
    {field: 'date', headerName: 'Fecha', width: 150},
    {field: 'boxes', headerName: 'Cajas', width: 100},
  ]

  const initialState_data = {
    columns: {
        columnVisibilityModel :{
            checkbox: false,
            id: false,
            worker_id: false,
            name: true,
            rut: true,
            supervisor: true,
            date: true,
            boxes: true,
        }
    },
    pagination: { paginationModel: { pageSize: 10 } },
  }

function CustomToolbar({name,dateInfo}){
  const navigate = useNavigate(); 
  return (
    <GridToolbarContainer>
      <Hidden smDown>
      <GridToolbar csvOptions={{
        fileName: 'Resumen ' + name + ' ' + dateInfo,
        utf8WithBom: true,
        }}
      />
      </Hidden>
      <Button
          variant="text"
          color="primary"
          onClick={() => navigate(`/operation/addbox/`)}
          sx={{ marginTop: theme => theme.spacing(0.5), marginLeft: theme => theme.spacing(0)}}
          startIcon={<AddBoxIcon />}
      >
          Agregar
      </Button>
    </GridToolbarContainer>
  );
}

export default function WorkerViewTable({worker,dateStart,dateEnd}) {
  const navigate = useNavigate();
  const { authToken } = useContext(AuthContext);
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState(columns_data);
  const [initialState, setInitialState] = useState(initialState_data);
  const isXsScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [dateString, setDateString] = useState('');

  let width_value = 200;
  let colum_visivility= true;

  useEffect(() => {
    setIsLoading(true);

    if(!worker){
      return;
    }

    if(!dateStart){
      return;
    }

    if(!dateEnd){
      return;
    }

    console.log("WorkerViewTable");
    console.log(worker);

    const dateStartWithTimeSetToNoon = new Date(dateStart);
    dateStartWithTimeSetToNoon.setHours(12, 0, 0, 0);
    const dateStartString = dateStartWithTimeSetToNoon.toISOString().split('T')[0];
    setDateString(dateStartString);
    console.log(dateStartString);

    const dateEndWithTimeSetToNoon = new Date(dateEnd);
    dateEndWithTimeSetToNoon.setHours(12, 0, 0, 0);
    const dateEndString = dateEndWithTimeSetToNoon.toISOString().split('T')[0];
    setDateString(dateEndString);
    console.log(dateEndString);
    
    const fetchWorkers = async () => {
      setIsLoading(true);
      const payload = {
        workerId : worker.trabajador_id,
        dateStart: dateStartString,
        dateEnd: dateEndString,
      };
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/boxes/getWorkerViewTable`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`
          },
          body: JSON.stringify(payload),
      });

      if (response.ok) {
          const boxes = await response.json();
          let boxlist = [];
          let index = 0;
          boxes.map((box) => { 
            boxlist.push(
              createData(
                index,
                box.id, box.nombre, 
                box.rut, box.supervisor,
                box.fecha, box.cajas
              )
            )
            index++;
          })
          setRows(boxlist);
          setIsLoading(false);
      } else {
          // Manejar el error
          setIsLoading(false);
      }
    };

    fetchWorkers();
  }, [authToken, worker, dateStart, dateEnd]);

  if (isLoading) {
    return <p>Cargando Tabla...</p>;
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
            //checkboxSelection
            disableRowSelectionOnClick
            density="compact"
            columns = {columns_data}
            rows = {rows}
            initialState = {initialState_data}
            slots={{ toolbar: () => <CustomToolbar name={worker.trabajador_name} dateInfo={dateString}/> }} 
            sx={{ '--DataGrid-overlayHeight': '300px' }}
            pageSizeOptions={[5, 10, 25]}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        />
    </div>
  );
}