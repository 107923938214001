import React from 'react';
import { Box, Typography } from '@mui/material';
import TruckSummaryCard from '../../components/SummaryCards/truckSummary';
import TractorReciveSummaryCard from '../../components/SummaryCards/tractorReciveSummary';
import OrchardTotalSummaryCard from '../../components/SummaryCards/orcharSummary';
import BookKeeperSummaryCard from '../../components/SummaryCards/bookkeeperSummary';
const Dashboard = () => {
    
    // Get the current date object
    var today = new Date();
    var offset = today.getTimezoneOffset();
    today = new Date(today.getTime() - (offset * 60 * 1000));
    var dateInfo = today.toISOString().slice(0, 10);

    return (
        <Box>
            <Typography variant="h4" sx={{marginBottom:4}}>Panel Despacho</Typography>
            <Box sx={{display:'flex', flexWrap:'wrap', justifyContent:'space-around'}}>
                <OrchardTotalSummaryCard dateInfoString={dateInfo} />
                <BookKeeperSummaryCard dateInfoString={dateInfo} />
                <TractorReciveSummaryCard dateInfoString={dateInfo} />
                <TruckSummaryCard dateInfoString={dateInfo} />
            </Box>
        </Box>
    );
};

export default Dashboard;
