/*
Usando @mui/material, crea un componente que despligue una tarjeta con la información de un camión.
mostrando empresa, bins, totes y esponjas.
esta informacion se extrea mediante un post a un servidor.
el componente se llama truckSummaryCard
Debe tener un titulo usando una tipografia centrada de tamaño h6 que diga: "Carga Camion"
*/


import React, { useEffect, useState, useContext} from 'react';
import { AuthContext } from '../../components/authContext';
import {Box, Grid, Typography} from '@mui/material';
import CustomDateSelector from '../../components/customDateSelector';
import OrchardTotalSummaryCard from '../../components/SummaryCards/orcharSummary';
import TractorReciveSummaryCard from '../../components/SummaryCards/tractorReciveSummary';
import TruckSummaryCard from '../../components/SummaryCards/truckSummary';
import BookKeeperSummaryCard from '../../components/SummaryCards/bookkeeperSummary';

const OperationSummary = () => {
    const { authToken } = useContext(AuthContext);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dateInfo, setDateInfo] = useState(new Date().toISOString().split('T')[0]);

    useEffect(() => {

    }, [authToken]);

    const handleDateChange = (date) => {
        const dateInfoString = date.toISOString().split('T')[0];
        setDateInfo(dateInfoString);
    };

    return (
        <Box>

            <Typography variant="h5" sx={{display:'flex', justifyContent:'space-around'}}>
                Resumen de Operación
            </Typography>
            <CustomDateSelector
                currentDate={selectedDate}
                onDateSelected={handleDateChange}
            />

            <Box sx={{display:'flex', flexWrap:'wrap', justifyContent:'space-around'}}>
                <OrchardTotalSummaryCard dateInfoString={dateInfo} />
                <BookKeeperSummaryCard dateInfoString={dateInfo} />
                <TractorReciveSummaryCard dateInfoString={dateInfo} />
                <TruckSummaryCard dateInfoString={dateInfo} />
            </Box>
        </Box>
    );
};

export default OperationSummary;
