// src/components/notes.js
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from '../components/authContext';
import { useNavigate } from "react-router-dom";

const Notes = () => {
    const { authToken } = useContext(AuthContext);
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!authToken) {
            navigate('/login');
        } else {
            navigate('/operation/summary');
        }
    }, [authToken]);

    return (
        <div>
            <h1>Hello from Notes</h1>
        </div>
    );
};

export default Notes;