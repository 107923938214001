import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../../components/authContext';
import { Grid, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, FormControlLabel, Box} from '@mui/material';


const BoxPropertys = ({onPropertyChange, onBack}) => {
    const { authToken } = useContext(AuthContext);
    const [variety, setVariety] = useState('');
    const [numBoxes, setNumBoxes] = useState(1);
    const [damages, setDamages] = useState({
        picadura: false,
        pudricion: false,
        partidura: false,
        palito: false,
        insecto: false,
        calibre: false,
        color: false,
        granizo: false,
    });

    const [varietiesNames, setVarietiesNames] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        fetch(`${process.env.REACT_APP_SERVER_URL}/api/variety/getActiveVarietiesNames`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setVarietiesNames(data);
            })
            .catch(error => console.log(error));
        ;
        setIsLoading(false);
    }, [authToken]);

    const handleVarietyChange = (event) => {
        setVariety(event.target.value);
    };

    const handleNumBoxesChange = (event) => {
        setNumBoxes(event.target.value);
    };

    const handleDamagesChange = (event) => {
        setDamages({ ...damages, [event.target.name]: event.target.checked });
    };

    if (isLoading) {
        return <p>Cargando...</p>;
    }

    return (
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Grid item container marginBottom={0} spacing={1} xs={12} sm={9} md={6}>
                <Grid item xs={12} sm={8}>
                    <FormControl variant="standard" sx={{ m: theme => theme.spacing(0), minWidth: '100%' }}>
                        <InputLabel>Variedad</InputLabel>
                        <Select
                            value={variety}
                            onChange={handleVarietyChange}
                        >
                        { varietiesNames.map((variety) => (
                            <MenuItem key={varietiesNames.indexOf(variety)} value={variety}>{variety}</MenuItem>
                            ))
                        }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl variant="standard" sx={{ m: theme => theme.spacing(0), minWidth: '100%' }}>
                        <InputLabel>Número de Cajas</InputLabel>
                        <Select
                            value={numBoxes}
                            onChange={handleNumBoxesChange}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <FormControlLabel
                        control={<Checkbox checked={damages.picadura} onChange={handleDamagesChange} name="picadura" />}
                        label="Picadura"
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <FormControlLabel
                        control={<Checkbox checked={damages.pudricion} onChange={handleDamagesChange} name="pudricion" />}
                        label="Pudrición"
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <FormControlLabel
                        control={<Checkbox checked={damages.partidura} onChange={handleDamagesChange} name="partidura" />}
                        label="Partidura"
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <FormControlLabel
                        control={<Checkbox checked={damages.palito} onChange={handleDamagesChange} name="palito" />}
                        label="Sin Palito"
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <FormControlLabel
                        control={<Checkbox checked={damages.insecto} onChange={handleDamagesChange} name="insecto" />}
                        label="Insecto"
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <FormControlLabel
                        control={<Checkbox checked={damages.calibre} onChange={handleDamagesChange} name="calibre" />}
                        label="Calibre"
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <FormControlLabel
                        control={<Checkbox checked={damages.color} onChange={handleDamagesChange} name="color" />}
                        label="Verde"
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <FormControlLabel
                        control={<Checkbox checked={damages.granizo} onChange={handleDamagesChange} name="granizo" />}
                        label="1/2 Luna"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Button sx={{ width:'100%'}} variant="contained" color="error" onClick={() => onBack()}>
                        Atras
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Button sx={{ width:'100%'}} variant="contained" color="primary" onClick={() => onPropertyChange(variety, numBoxes, damages)}
                    disabled={!variety}>
                        Siguiente
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default BoxPropertys;
