import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../../components/authContext';
import { Box, TextField , Typography, Checkbox, Button, FormControlLabel } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import Alert from '@mui/lab/Alert';

const DeleteItemForm = () => {
    const { itemId, itemName, itemType } = useParams();
    const [tableName, setTableName] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();
    const { authToken } = useContext(AuthContext);

    useEffect(() => {
        switch (itemType) {
            case 'company':
                setTableName('empresas');
                break;
            case 'tractor':
                setTableName('tractores');
                break;
            case 'variety':
                setTableName('variedades');
                break;
            case 'user':
                setTableName('usuarios');
                break;
            case 'worker':
                setTableName('trabajadores');
                break;
            default:
                return;
        }
    }, []);


    const handleDelete = async () => {
        let myBody = null; 

        if (inputValue === itemName) {
            
            // Perform the deletion logic here

            switch (itemType) {
                case 'company':
                    myBody = {empresas_id : itemId};
                    break;
                case 'tractor':
                    myBody = {tractor_id : itemId};
                    break;
                case 'variety':
                    myBody = {variedad_id : itemId};
                    break;
                case 'user':
                    myBody = {email : itemId};
                    break;
                case 'worker':
                    myBody = {trabajador_id : itemId};
                    break;
                default:
                    return;
            }

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/${itemType}/delete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(myBody)

            })
            
            //check if the response is ok
            if (response.ok) {
                setConfirmDelete(true);
            }
        }
    };

    const handleCancel = () => {
        navigate(`admin/${itemType}Dashboard`);
    };

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };
    
    const MyStyledTypography = styled(Typography)(({ theme }) => ({
        maxWidth: '400px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    }));

    // Styled button
    const MyStyledButton = styled(Button)(({ theme }) => ({
        maxWidth: '200px',
        margin: theme.spacing(2),
        alignSelf: 'center',
    }));

    const MyStyledBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '400px',
        margin: '0 auto',
    }));

    return (
        <div>
            {!confirmDelete ? (
                <form>
                    <Box display="flex" flexDirection="column"  maxWidth="400px" margin="0 auto">
                        <MyStyledTypography variant="h6" align="center">
                            Esta seguro que quiere eliminar el item "{itemName}" de la tabla de {tableName}?
                        </MyStyledTypography>
                        <MyStyledTypography>
                            Para confirmar, escriba {itemName} en el siguiente cuadro:
                        </MyStyledTypography>
                        <TextField
                            label="Escriba el nombre del elemento"
                            value={inputValue}
                            onChange={handleChange}
                        />
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <MyStyledButton
                                variant="contained"
                                color="secondary"
                                onClick={handleCancel}
                            >
                                Cancelar
                            </MyStyledButton>
                            <MyStyledButton
                                variant="contained"
                                color="error"
                                onClick={handleDelete}
                                disabled={inputValue !== itemName}
                            >
                                Eliminar
                            </MyStyledButton>
                        </Box>
                    </Box>
                </form>
            ) : (
                <Box display="flex" flexDirection="column"  maxWidth="400px" margin="0 auto"> 
                    <MyStyledTypography variant="h6" align='center'>
                        El item "{itemName}" ha sido eliminado de la tabla {tableName}.
                    </MyStyledTypography>
                    <MyStyledButton
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate(`/admin/${itemType}Dashboard`)}
                    >
                    Volver a la tabla
                    </MyStyledButton>
                </Box>
            )}
        </div>
    );
};

export default DeleteItemForm;
