import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from './authContext';
import { Grid, TextField, Box, Typography, Autocomplete, Card, CardContent } from '@mui/material';

const SearchWorker = ({ onWorkerFound }) => {
    const { authToken } = useContext(AuthContext);
    const [worker, setWorker] = useState(null);
    const [workers, setWorkers] = useState([]);

    useEffect(() => {
        const fetchWorkers = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/worker/getAll`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
            });

            if (response.ok) {
                const workers = await response.json();
                setWorkers(workers);
                console.log(workers);
            } else {
                // Manejar el error
            }
        };

        fetchWorkers();
    }, [authToken]);

    const handleSearch = (worker) => {
        // Aquí puedes realizar la lógica de búsqueda del trabajador
        // utilizando el nombre y/o el rut

        // Supongamos que encontramos al trabajador y obtenemos su ID

        // Enviamos la señal al componente padre con el ID del trabajador
            onWorkerFound(worker);
    };

    return (
    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" width="100%">
        <Grid item container marginBottom={3} spacing={1} xs={12} sm={9} md={6}> 
            <Grid item xs={12}>
                <Typography variant="h6" component="div" color="primary" textAlign={"center"}>
                    Buscar Trabajador
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    freeSolo
                    id="WorkerSearch"
                    options={workers}
                    disableClearable
                    getOptionLabel={(option) => `${option.trabajador_name} ${option.trabajador_rut}`}
                    renderOption={(params,option) => (
                        <Card sx={{ minWidth: 275, maxHeight:80}} {...params}>
                            <CardContent sx={{ mt: 1 }}>
                                <Typography color="text.primary" >
                                    {option.trabajador_name}
                                </Typography>
                                <Typography component="div" color="text.secondary">
                                    {option.trabajador_rut}
                                </Typography>
                            </CardContent>
                        </Card>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Busqueda por nombre o rut"
                            InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            }}
                        />
                    )}
                    onChange={(event, value) => {
                       value ? onWorkerFound(value) : onWorkerFound(null);
                       console.log(value);
                    }}
                />
            </Grid>
        </Grid>
    </Box>
  );
};

export default SearchWorker;
