import React from 'react';
import { Box, Tab, Tabs, tabsClasses } from '@mui/material';
import Dashboard from './dispatcherDashboard';
import Add2Truck from './dispatcherAdd2Truck';
import ReciveTractor from './dispatcherReciveTractor';
import TruckTransactions from './Tables/truckTable';
import ReciveTractorTransactions from './Tables/tractorTable';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const DispatcherLayout = ({children}) => {
    const [activeTab, setActiveTab] = React.useState(0);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box sx={{ width: '100%' }} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Box marginBottom={2}>
                <Tabs 
                    value={activeTab} 
                    onChange={handleChange} 
                    aria-label="Dispatcher Tabs"   
                    variant="scrollable"
                    scrollButtons
                    sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                    }}
                >
                    <Tab icon={<DashboardIcon />} iconPosition="bottom" label="Panel" />
                    <Tab icon={<LocalShippingIcon />} iconPosition="bottom" label="Agregar Camion" />
                    <Tab icon={<AgricultureIcon />} iconPosition="bottom" label="Recivir Tractor" />
                    <Tab icon={<LocalShippingIcon />} iconPosition="bottom" label="Tabla" />
                    <Tab icon={<AgricultureIcon />} iconPosition="bottom" label="Tabla" />

                </Tabs>
            </Box>
            {activeTab === 0 && <Dashboard />}
            {activeTab === 1 && <Add2Truck />}
            {activeTab === 2 && <ReciveTractor/>}
            {activeTab === 3 && <TruckTransactions/>}
            {activeTab === 4 && <ReciveTractorTransactions/>}
        </Box>
    );
};

export default DispatcherLayout;
