import React, { useContext } from 'react';
import { Navigate, useLocation, Outlet  } from 'react-router-dom';
import { AuthContext } from './authContext';

const PrivateRoute = ({ roles }) => {
  const { authToken, userType } = useContext(AuthContext);
  let location = useLocation();

  // Check if the user is authenticated
  if (!authToken) {
    // Redirect to login if not authenticated
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // Check if the user has the correct role
  if (!roles.includes(userType)) {
    // Redirect to home if the user doesn't have the correct role
    return <Navigate to="/" state={{ from: location }} />;
  }

  // If the user is authenticated and has the correct role, render the children
  return <Outlet />;
}

export default PrivateRoute;

// mc combo bacon chedar dos carnes
