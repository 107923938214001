import React, { useState } from 'react';
import { Box, Stepper, Step, StepLabel, Button, Grid } from '@mui/material';
import ReciveTractor_Items from     './ReciveTractor/ReciveTractor_Items.js';
import ReciveTractor_Summary from   './ReciveTractor/ReciveTractor_Summary';
import ReciveTractor_Results from   './ReciveTractor/ReciveTractor_Results';

const steps = ['Items', 'Resumen', 'Resultados'];

const ReciveTractor = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [bins, setBins] = useState(0);
    const [totes, setTotes] = useState(0);
    const [tractor, setTractor] = useState(null);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <ReciveTractor_Items
                        bins={bins}
                        setBins={setBins}
                        totes={totes}
                        setTotes={setTotes}
                        tractor={tractor}
                        setTractor={setTractor}
                        onNext={handleNext}
                    />
                );
            case 1:
                return (
                    <ReciveTractor_Summary
                        bins={bins}
                        totes={totes}
                        tractor={tractor}
                        onConfirm={handleNext}
                    />
                );
            case 2:
                return <ReciveTractor_Results />;
            default:
                return null;
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="stretch" sx={{ m: theme => theme.spacing(0), minWidth: '100%' }} >
            <Stepper activeStep={activeStep} alternativeLabel sx={{ m: theme => theme.spacing(0), width: '100%' }}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                {getStepContent(activeStep)}
            </Box>
        </Box>
    );
};

export default ReciveTractor;
