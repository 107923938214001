// src/pages/Create.js
import React from 'react';
import { Button, Container, Typography } from '@mui/material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import styled from '@emotion/styled';
import { TextField } from '@mui/material';


const MyStyledTypography = styled(Typography)({
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 2,

  });
const StyledTextField = styled(TextField)({
    marginTop: 20,
    marginBottom: 20,
    display: 'block'
});


const Create = () => {

    return (
        <Container>
            <MyStyledTypography
                variant='h6'
                color="textSecondary"
                component="h2"
                gutterBottom
            >
                Create a new note
            </MyStyledTypography>

            <form noValidate autoComplete='off'>    
                <StyledTextField 
                    label="Note Title" 
                    variant="outlined" 
                    color="primary" 
                    fullWidth 
                    required
                />
            </form>
            <Button 
                onClick={() => console.log('You clicked me')}
                type='submit' 
                variant='contained'
                color='secondary'
                endIcon={<AcUnitIcon />}
            >
                Submit
            </Button>

            <Button 
                onClick={() => console.log('You clicked me')}
                variant='contained'
                color='primary'
                endIcon={<AcUnitIcon />}
            >
                buttons
            </Button>

        </Container>
    );
};

export default Create;