import React, { useState } from 'react';
import { Box, Stepper, Step, StepLabel, Button, Grid } from '@mui/material';
import Add2Truck_Items from './Add2Truck/add2Truck_Items';
import Add2Truck_Summary from './Add2Truck/add2Truck_Summary';
import Add2Truck_Results from './Add2Truck/add2Truck_Results';

const steps = ['Items', 'Resumen', 'Resultados'];

const Add2Truck = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [bins, setBins] = useState(0);
    const [totes, setTotes] = useState(0);
    const [esponjas, setEsponjas] = useState(0);
    const [variety, setVariety] = useState('');
    const [empresa, setEmpresa] = useState(null);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setBins(0);
        setTotes(0);
        setEsponjas(0);
        setVariety('');
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Add2Truck_Items
                        bins={bins}
                        setBins={setBins}
                        totes={totes}
                        setTotes={setTotes}
                        esponjas={esponjas}
                        setEsponjas={setEsponjas}
                        variety={variety}
                        setVariety={setVariety}
                        empresa={empresa}
                        setEmpresa={setEmpresa}
                        onNext={handleNext}
                    />
                );
            case 1:
                return (
                    <Add2Truck_Summary
                        bins={bins}
                        totes={totes}
                        esponjas={esponjas}
                        variety={variety}
                        empresa={empresa}
                        onConfirm={handleNext}
                    />
                );
            case 2:
                return <Add2Truck_Results />;
            default:
                return null;
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="stretch" sx={{ m: theme => theme.spacing(0), minWidth: '100%' }} >
            <Stepper activeStep={activeStep} alternativeLabel sx={{ m: theme => theme.spacing(0), width: '100%' }}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                {getStepContent(activeStep)}
            </Box>
        </Box>
    );
};

export default Add2Truck;
