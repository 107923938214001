
import React, { useState } from 'react';
import { Box,Typography, Hidden, Grid } from '@mui/material';
import CustomDateSelector from '../../components/customDateSelector';
import WorkerDetailSummary from './Payroll/workerDetailSummary';

const WorkerDetailSummaryLayout = () => {
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date.toDate());
        console.log(date.toDate());
    };

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date.toDate());
        console.log(date.toDate());
    };

    return (
        <Box p={2}>
            <Hidden smDown>
                <Typography variant="h6" color="primary">Resumen Cajas Por Intervalo</Typography>
            </Hidden>
            <Grid container>
                <Grid item xs={12} sm={6}>
                <CustomDateSelector
                    currentDate={selectedStartDate}
                    onDateSelected={handleStartDateChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                <CustomDateSelector
                    currentDate={selectedEndDate}
                    onDateSelected={handleEndDateChange}
                    />
                </Grid>
            </Grid>
            <WorkerDetailSummary dateStart={selectedStartDate} dateEnd={selectedEndDate}/>
        </Box>
    );
};

export default WorkerDetailSummaryLayout;

