import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../components/authContext';
import { Container, InputAdornment, Box, TextField , Typography, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useNavigate } from 'react-router-dom';

const WorkerDashboard = () => {
    const [workers, setWorkers] = useState([]);
    const { authToken } = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchWorkers = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/worker/getAll`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
            });

            if (response.ok) {
                const workers = await response.json();
                setWorkers(workers);
            } else {
                // Manejar el error
            }
        };

        fetchWorkers();
    }, [authToken]);

    const handleSearchChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setSearchTerm(inputValue);
    };

    const handleDelete = async (itemId, itemName, itemType) => {
        navigate(`/forms/deleteItem/${itemId}/${itemName}/${itemType}`);
    };

    const handleEdit = async (worker_id) => {
    };

    return (
        <Container>
            <Typography variant="h6" component="div">
                Administración de Trabajadores
            </Typography>
            <Toolbar>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <form onSubmit={handleSearchSubmit}>
                            <TextField
                                label="Buscar trabajadores"
                                variant="standard"
                                size="small"
                                style={{ marginRight: '1rem' }}
                                value={inputValue}
                                onChange={handleSearchChange}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                    <IconButton color="secondary" type="submit">
                                        <SearchIcon />
                                    </IconButton>
                                    </InputAdornment>
                                ),
                                }}
                            />
                        </form>
                        <IconButton color="secondary" onClick={() => console.log('Agregar filtro')}>
                            <FilterListIcon />
                        </IconButton>
                        <IconButton color="secondary" onClick={() => navigate("/forms/newWorker")}>
                            <PersonAddIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Toolbar>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>RUT</TableCell>
                        <TableCell>Banda</TableCell>
                        <TableCell>Acciones</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {workers.filter(worker => 
                            worker.trabajador_name.toLowerCase().includes(searchTerm.toLowerCase()) || 
                            worker.trabajador_rut.includes(searchTerm)
                        ).map((worker) => (
                            <TableRow key={worker.trabajador_id}>
                                <TableCell>{worker.trabajador_name}</TableCell>
                                <TableCell>{worker.trabajador_rut}</TableCell>
                                <TableCell>{worker.trabajador_banda}</TableCell>
                                <TableCell>
                                    <IconButton color="secondary" onClick={() => handleEdit(worker.trabajador_id)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton color="error" onClick={() =>  handleDelete(worker.trabajador_id, worker.trabajador_name,"worker")}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default WorkerDashboard;