import React, {useState} from 'react';
import {Box} from '@mui/material';
import CustomDateSelector from '../../../components/customDateSelectorV2';

const WorkerViewSelectDates = ({dateStart, dateEnd, onDateStartChange, onDateEndChange}) => {
    const [selectedDateStart, setSelectedDateStart] = useState(dateStart);
    const [selectedDateEnd, setSelectedDateEnd] = useState(dateEnd);

    const handleDateStartChange = (date) => {
        setSelectedDateStart(date.toDate());
        onDateStartChange(selectedDateStart);
    };

    const handleDateEndChange = (date) => {
        setSelectedDateEnd(date.toDate());
        onDateEndChange(selectedDateEnd);
    };

    return (
        <Box sx={{ width: '100%' }} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <CustomDateSelector
                text='Fecha Inicio'
                currentDate={selectedDateStart}
                onDateSelected={handleDateStartChange}
            />
            <CustomDateSelector
                text='Fecha Fin'
                currentDate={selectedDateEnd}
                onDateSelected={handleDateEndChange}
            />
        </Box>
    );
};

export default WorkerViewSelectDates;