import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../../components/authContext';
import { Grid, Button, Typography, Box, Card, CardContent, Avatar, Hidden, Table, TableBody, TableRow, TableCell} from '@mui/material';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import { useNavigate } from 'react-router-dom';



const BoxResult = ({onBack, onReset, caja}) => {
  const { authToken, userName } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [cajasActuales, setCajasActuales] = useState(0);

  const dateInfo = new Date().toISOString().split('T')[0];

  useEffect(() => {
    const payload = {
        dateInfo: dateInfo,
        trabajador_id: caja.worker.trabajador_id
    };
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/boxes/getBoxCountByWorker`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(payload),
    })
        .then(response => response.json())
        .then(data => {
            //check if data has boxCount field
            if (data.Cajas) {
                setCajasActuales(data.Cajas);
            }
        })
        .catch(error => console.log(error));
    ;
    setIsLoading(false);
  }, [authToken]);

  if (!caja) {
    return <p>Cargando caja...</p>;
  }

  return (
       <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Grid item container spacing={2} xs={12} sm={8} md={6}>
                <Grid item xs={12}>
                    <Typography variant="h6" component="div" color="primary" textAlign={"center"}>
                        Resumen Operacion
                    </Typography>
                    <Card variant="outlined">
                        <CardContent>
                            <Hidden smDown>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                                        <AllInboxIcon />
                                    </Avatar>
                                </Box>
                            </Hidden>
                            <Grid container>
                                <Grid item xs={5} sm={3}>
                                    <Typography fontWeight="bold">
                                        Nombre:
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} sm={9}>
                                    <Typography color="text.primary">
                                        {caja.worker.trabajador_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} sm={3}>
                                    <Typography fontWeight="bold">
                                        Rut:
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} sm={9}>
                                    <Typography color="text.primary">
                                        {caja.worker.trabajador_rut}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} sm={3}>
                                    <Typography fontWeight="bold">
                                        Tiene:
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} sm={9}>
                                    <Typography color="text.primary">
                                        {`${cajasActuales} Cajas`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Button sx={{ width:'100%'}} variant="contained" color="error" onClick={() => onReset()} >
                        Agregar Otra Caja
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Button sx={{ width:'100%'}} variant="contained" color="primary" onClick={()=> navigate("/operation/dashboard")}>
                        Ir a la tabla
                    </Button>
                </Grid>
            </Grid>
        </Box>
  );
};

export default BoxResult;
