import React, {useState}from 'react';
import dayjs from 'dayjs';
import { Box, Grid, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

const CustomDateSelector = ({ onDateSelected, currentDate }) => {
    const [selectedDate, setSelectedDate] = useState(dayjs(currentDate));
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleActualize = () => {
        console.log("actualize");
        if(!selectedDate){
            console.log("date is null");
            return;
        }

        if (selectedDate.isValid()) {
            onDateSelected(selectedDate);
            console.log("date valid");
            console.log(selectedDate);
        }
        else{
            console.log("date not valid");
        }
    }

    return (
        <Box margin={1}>
            <Grid container>
                <Grid item xs={12} sm={4} md={3} container direction="row" marginBottom={1}>
                    <Typography marginRight={1} paddingTop={1}>
                        Fecha:
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            format='DD/MM/YYYY'
                            slotProps={{ 
                                textField: { 
                                    size: 'small',
                                    variant: 'standard',
                                    sx: { width: 130, paddingTop: 1 },
                                },
                                openPickerButton: { color: 'secondary' },
                            }}
                            color="secondary"
                            value={selectedDate}
                            onChange={handleDateChange}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={8} md={9} container justifyContent="flex-start">
                    <Box margin={1}>
                    <Button color='secondary' variant='contained' endIcon={<RefreshIcon />} onClick={handleActualize}>
                        Actualizar
                    </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CustomDateSelector;
