import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../components/authContext';
import { Container, InputAdornment, Box, TextField , Typography, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

// ...otros imports...

const CompanyDashboard = () => {
    const [companies, setCompanies] = useState([]);
    const { authToken } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/company/getAll`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => setCompanies(data))
            .catch(error => console.log(error));
    }, []);

    const handleAddCompany = () => {
        navigate("/forms/newCompany")
    };

    const handleDelete = async (itemId, itemName, itemType) => {
        navigate(`/forms/deleteItem/${itemId}/${itemName}/${itemType}`);
    };

    const handleEdit = async (empresas_id) => {
       
    };

    return (
        <Container>
            <Typography variant="h6" component="div">
                Administración de Empresas
            </Typography>
            <Toolbar>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Box>
                        <TextField
                            label="Buscar empresas"
                            variant="outlined"
                            size="small"
                            style={{ marginRight: '1rem' }}
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton color="secondary" onClick={() => console.log('Buscar')}>
                                    <SearchIcon />
                                </IconButton>
                                </InputAdornment>
                            ),
                            }}
                        />
                        <IconButton color="secondary" onClick={() => console.log('Agregar filtro')}>
                            <FilterListIcon />
                        </IconButton>
                        <IconButton color="secondary" onClick={handleAddCompany}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Toolbar>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nombre de Empresa</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {companies.map((company) => (
                            <TableRow key={company.empresas_id}>
                                <TableCell>{company.empresa_nombre}</TableCell>
                                <TableCell>
                                    <IconButton color="secondary" onClick={() => handleEdit(company.empresas_id)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton color="error" onClick={() => handleDelete(company.empresas_id, company.empresa_nombre,"company")}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default CompanyDashboard;