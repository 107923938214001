import React, { createContext, useState, useEffect } from 'react';
import {jwtDecode} from "jwt-decode";
import { useNavigate } from 'react-router-dom';


export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [authToken, setAuthToken] = useState(null);
    const [userType, setUserType] = useState(null);
    const [userName, setUserName] = useState(null); // Agrega esto
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('MCToken');
        if (token) {
          // Configurar el estado de autenticación basado en el token recuperado

          const decodedToken = jwtDecode(token);
          const expirationDate = decodedToken.exp;
          const currentTimestamp = Date.now() / 1000;

          if (expirationDate < currentTimestamp) {
            // Si el token ha expirado, desloguear al usuario
            setAuthToken(null);
            setUserType(null);
            setUserName(null);
            localStorage.removeItem('MCToken');
            localStorage.removeItem('MCROLE');
            localStorage.removeItem('MCUSER');
            setIsLoading(false);
            navigate('/login');
            return;
          }

          setAuthToken(token);
        }



        const role = localStorage.getItem('MCROLE');
        if (role) {
          // Configurar el estado de autenticación basado en el token recuperado
          setUserType(role);
        }
        const username = localStorage.getItem('MCUSER');
        if (username) {
          // Configurar el estado de autenticación basado en el token recuperado
          setUserName(username);
        }
        setIsLoading(false);
        //console.log("UseEffect AuthContext" + token  +" " + role + " " + username);
      }, []);
    
    if (isLoading) {
    return <div>Cargando...</div>; // O un componente de carga
    }
    
    return (
        <AuthContext.Provider value={{ authToken, setAuthToken, userType, setUserType, userName, setUserName }}>
            {children}
        </AuthContext.Provider>
    );
};