import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ReciveTractor_Results = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/dispatcher');
    };

    return (
        <Box sx={{marginTop:2, display:'flex', flexDirection:'column', justifyContent:'center'}}>
            <Typography variant="h5" sx={{display:'flex', justifyContent:'center'}}>Transacción realizada</Typography>
            <Typography variant="body1" sx={{marginTop:1, marginBottom:2}}>Elementos revividos</Typography>
            <Button variant="contained" onClick={handleButtonClick}>
                Volver al Panel
            </Button>
        </Box>
    );
};

export default ReciveTractor_Results;
