/*
Usando @mui/material, crea un componente que despligue una tarjeta con la información de un camión.
mostrando empresa, bins, totes y esponjas.
esta informacion se extrea mediante un post a un servidor.
el componente se llama truckSummaryCard
Debe tener un titulo usando una tipografia centrada de tamaño h6 que diga: "Carga Camion"
*/


import React, { useEffect, useState, useContext} from 'react';
import { AuthContext } from '../authContext';
import {Box, Card, CardContent, Typography, CircularProgress, Button } from '@mui/material';

const TruckSummaryCard = ({dateInfoString}) => {
    const { authToken } = useContext(AuthContext);
    const [truckData, setTruckData] = useState([
        { empresa: '', variedad: '', totalBins: 0, totalTotes: 0, totalEsponjas: 0 }
    ]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);


    useEffect(() => {
        // Realizar la solicitud POST al servidor para obtener los datos del camión
        // y actualizar el estado de truckData con los datos recibidos
        setTruckData([]);

        fetch(`${process.env.REACT_APP_SERVER_URL}/api/dispatcher/getSumary`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({ dateInfo: dateInfoString }) // Add the datInfo field with the current date
        })
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data) && data.length >= 1) {
                    //for each tractor, add the total bins and totes
                    let total_bins = 0;
                    let total_totes = 0;
                    let total_esponjas = 0;
                    data.forEach((summary) => {
                        total_bins += parseInt(summary.totalBins);
                        total_totes += parseInt(summary.totalTotes);
                        total_esponjas += parseInt(summary.totalEsponjas);
                    });
                    data.push({empresa: data[0].empresa, variedad: 'Todas', totalBins: total_bins, totalTotes: total_totes, totalEsponjas: total_esponjas});
                    setTruckData(data);
                }
                setIsLoading(false);
            })
            .catch(error => console.log(error));

    }, [dateInfoString]);
    
    const handleNext = () => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % truckData.length);
    };

    const handlePrevious = () => {
        setCurrentIndex(prevIndex => (prevIndex - 1 + truckData.length) % truckData.length);
    };

    return (
        <Card sx={{maxWidth:250, margin:2}} >
            <CardContent>
                <Typography variant="h6"  color="primary" align="center" sx={{marginBottom:2}}>
                    Carga Camion
                </Typography>
                {isLoading ? (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {truckData.length === 0 ? (
                            <>
                                <Typography variant="body1">Fecha: {dateInfoString}</Typography>
                                <Typography variant="body1">Sin Informacion</Typography>
                            </>
                        ) : (
                            <>
                                <Typography variant="body1">Fecha: {dateInfoString}</Typography>
                                <Typography variant="body1">Empresa: {truckData[currentIndex].empresa}</Typography>
                                <Typography variant="body1">Variedad: {truckData[currentIndex].variedad}</Typography>
                                <Typography variant="body1">Bins: {truckData[currentIndex].totalBins}</Typography>
                                <Typography variant="body1">Totes: {truckData[currentIndex].totalTotes}</Typography>
                                <Typography variant="body1">Esponjas: {truckData[currentIndex].totalEsponjas}</Typography>
                                <Box sx={{display:'flex', justifyContent:'space-around', marginTop:2, marginBottom:2}}>
                                    <Button sx={{margin:1}} variant="contained" onClick={handlePrevious}>Anterior</Button>
                                    <Button sx={{margin:1}} variant="contained" onClick={handleNext}>Siguiente</Button>
                                </Box>
                            </>
                        )}
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default TruckSummaryCard;
